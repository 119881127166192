.containerquote {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.listquote {
  width: fit-content;
  display: flex;
  list-style: none;
  gap: 4vmin;
  margin-top: 5em;

}

.list__item_quote {
  width: 50vmin;
  height: 35vmin;
  border-radius: 6vmin;
  border: 1px solid rgb(212, 212, 212);
  filter: drop-shadow(0px 0px 1.2vmin rgba(255, 255, 251, 0.877));
object-fit: cover;
}


.qtext {
  font-size: 5vmin;
  position: absolute;
  display: inline-block;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-weight: 500;
  letter-spacing: 0.05vmin;  
  color: #ffffffe1;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  padding: 0;
  justify-content: center;
  margin-top: -12em;
  filter: drop-shadow(0px 0px 1vmin rgba(255, 255, 251, 0.877));

}

#whitequote {
  color: #ffffffd3;
  position: absolute;
  font-size: 3vmin;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  padding: 0;
  margin-top: 16.5vmin;
  justify-content: center;
  border: 1px solid rgb(212, 212, 212);
  border-radius: 20vmin;
  width: 20vmin;
}

#moreq {
  border: 0px solid rgba(212, 212, 212, 0);
  font-size: 3vmin;
  filter: drop-shadow(0px 0px 1vmin rgba(255, 255, 251, 0.877));

}

.myq {
  border: 0px solid rgba(212, 212, 212, 0);
  width: 10vmin;
  text-align: center;
  margin-left: 32vmin;
  margin-top: 8vmin;
  justify-content: center;

}










