
.flexo {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 95vmin;
    z-index: 0;
    background: #1a1919;

}




#welcome {
    flex-direction: column;
    font-size: 2rem;
}

#issue,
#solution {
    flex-direction: column;
}

#issue .quotecard,
#solution .quotecard {
    margin: 10vmin 0vmin;
    background: #444;
    border-radius: 0.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vmin;
    width: 90vmin;
    position: relative;
    background-position: 50%;
}

@media only screen and (width>=1250px) {

    #issue .quotecard,
    #solution .quotecard {
        height: 60vmin;
        width: 70%;


    }

}

.quotecard:after {
    content: "";
    display: block;
    position: absolute;
    height: 70%;
    width: 100%;
    z-index: 1;
    border-radius: 0.5rem;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
}

.quotecard::before {
    content: "";
    display: block;
    position: absolute;
    height: 40%;
    width: 100%;
    z-index: 1;
    border-radius: 0.5rem;
    left: 0;
    top: 0;
    background-image: linear-gradient(170deg, rgba(0, 0, 0, 0.753),
            rgba(3, 0, 0, 0), rgba(255, 0, 0, 0));
}



.titlequote {
    position: absolute;
    justify-content: center;
    margin: auto;
    left: 0;
    right: 0;
    width: 90%;
    text-align: center;
    bottom: 10%;
    font-size: 2vmin;
    z-index: 2;
}


.titlequote h2 {
    font-size: 3vmin;
    color: rgba(254, 255, 255, 0.781);
    text-align: center;
    justify-content: center;
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-weight: 500;
    letter-spacing: 0.05vmin;
    line-height: 5.5vmin;
}


/* ------------- */
.heartwrapper {
    font-size: 3vmin;
    color: rgb(254, 255, 255);
    position: absolute;
    text-align: left;
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-weight: 500;
    letter-spacing: 0.05vmin;
    line-height: 5.5vmin;
    z-index: 1;
    margin-top: 2vmin;
    left: 2.5vmin;
    height: 5vmin;
    width: 5vmin;
}
.heartwrappermovies {
    font-size: 3vmin;
    color: rgb(254, 255, 255);
    position: absolute;
    justify-content: center;
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-weight: 500;
    letter-spacing: 0.05vmin;
    line-height: 5.5vmin;
    z-index: 1;
    margin: auto;
    margin-top: 2vmin;
    left: 0;
    right: 0;
    height: 4vmin;
    width: 4vmin;
    filter: drop-shadow(0px 3px 2px rgb(0, 0, 0));

}
.heartwrapper p {
    font-size: 3vmin;
    color: rgb(254, 255, 255);
    position: absolute;
    text-align: center;
    justify-content: center;
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-weight: 500;
    letter-spacing: 0.05vmin;
    line-height: 5.5vmin;
    z-index: 1;
    margin-top: 5vmin;
    width: 5vmin;
}

#empty-cont,
#full-cont {
    z-index: 1;
    position: absolute;
}

#empty-cont svg,
#full-cont svg {
    height: 5vmin;
    width: 4.9vmin;

}

#empty-cont {
    filter: invert(1);
}

#empty-cont.unlike {
    transform-origin: bottom center;
    animation: bounceCorazon 0.5s ease-out forwards;
}

#full-cont {

    height: 5vmin;
    width: 5vmin;
    cursor: pointer;
    opacity: 0;
}

#full-cont.activeheart {
    opacity: 1;
}


#full-cont.activeheart:before {
    animation: appearOndeS 0.5s ease-out forwards;
}

#full-cont.activeheart:after {
    animation: appearOndeB 0.5s ease-out forwards;
}

#full-cont.activeheart .view {
    animation: appearCorazon 0.2s ease-out forwards;
}

#full-cont.activeheart.enabled {
    animation: removeCorazon 0.5s ease-out forwards;
}

#full-cont:before {
    content: "";
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 100px;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

#full-cont:after {
    content: "";
    position: absolute;
    height: 110px;
    width: 110px;
    border-radius: 100px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.7);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

#full-cont .view {
    z-index: 10;
    height: 5vmin;
    width: 5vmin;
    overflow: hidden;
    position: absolute;
}

#full-connt {
    cursor: pointer;
    z-index: 10;
    height: 5vmin;
    width: 5.1vmin;
    overflow: hidden;
    position: absolute;
}


@keyframes appearCorazon {
    from {
        height: 0px;
    }

    25% {
        transform: rotate(15deg) scale(2);
    }

    75% {
        transform: rotate(-25deg) scale(5);
    }

    to {
        height: 50px;
        transform: rotate(0deg);
    }
}

@keyframes appearOndeS {
    from {
        height: 0px;
        width: 0px;
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    to {
        height: 100px;
        width: 100px;
        opacity: 0;
    }
}

@keyframes appearOndeB {
    from {
        height: 0px;
        width: 0px;
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    to {
        height: 110px;
        width: 110px;
        opacity: 0;
    }
}

@keyframes removeCorazon {
    10% {
        transform: scale(1);
    }

    50% {
        height: 120px;
        width: 120px;
    }

    to {
        transform: scale(0);
        height: 0px;
        width: 0px;
        opacity: 0;
    }
}

@keyframes bounceCorazon {
    10% {
        transform: rotateZ(5deg) translate(-50%, -50%);
    }

    50% {
        transform: rotateZ(-5deg) translate(-50%, -50%);
    }

    to {
        transform: rotateZ(0deg) translate(-50%, -50%);
    }
}



/* ----------------- */

.blur {
    position: fixed;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    background-position: center center;
    opacity: 0.15;
    filter: blur(4px);
}

.Quote {
    position: absolute;
    background-color: rgba(240, 248, 255, 0.836);
    top: 30%;
    left: 20%;
    transition: 1s;
    width: 80vmin;

}


.Quote_content {
    position: absolute;
    z-index: 20;
    font-size: 3vmin;
    text-wrap: balance;
    font-style: italic;
    opacity: 0;
    width: 55%;

}

[data-animation] .Quote_content {
    animation: slide 10s;
}



.Quote_autor {
    position: absolute;
    bottom: -2rem;
    left: -20%;
    text-align: right;
    font-size: 5vmin;
    font-weight: bold;
    white-space: nowrap;
    transform: translate(4rem, 100%);
}

.Quote_autor:before {
    content: "—";
    margin-right: 0.7rem;
}


.QuoteImage {
    position: absolute;
    z-index: -1;
    right: 10%;
    bottom: 10%;
    object-fit: cover;
    height: 60vmin;
}


@media only screen and (width<=600px) {
    .flexo {
        height: 120vmin;
    
    }
    
    .Quote_content {
        font-size: 4.6vmin;
        width: 100%;

    }

    .QuoteImage {
        width: auto;
        bottom: 0;

    }

    .Quote {
        top: 30%;
        width: 80%;
        left: 15%;
    }

}




@keyframes slide {
    0% {
        opacity: 0;
        transform: translateX(-100vw);
    }

    5% {
        opacity: 1;
        transform: translateX(-10%);
    }

    95% {
        opacity: 1;
        transform: translateX(10%);
    }

    100% {
        opacity: 0;
        transform: translateX(100vw);
    }
}



@keyframes slide2 {

    5% {
        opacity: 1;
        transform: translateX(-1%);
    }

    95% {
        opacity: 1;
        transform: translateX(-10%);
    }

}










.popup-quote-wrap {
    width: 100%;
    height: 100vh;
    /* top: -10vh; */
    top: 0%;

    display: none;
    position: fixed;
    left: 0px;
    content: "";
    background: rgba(0, 0, 0, 0.541);
    z-index: 100;
}

.popup-quote-box {
    width: 90%;
    height: 30%;
    font-family: 'Baloo Bhaijaan 2', cursive;
    padding: 5vh;

    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
    top: 30%;
    left: 50%;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    background: #fff;
    text-align: center;
}

.popup-quote-box h2 {
    color: #1a1a1a;
    padding: 3vh;

}

.popup-quote-box h6 {
    color: #888;
    /* padding: 2%; */

}

.popup-quote-box .close-btn {
    width: 35px;
    height: 35px;
    display: inline-block;
    position: absolute;
    top: 10px;
    z-index: 87;
    right: 10px;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    border-radius: 1000px;
    background: #d75f70;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    line-height: 3.5vh;
}

.popup-quote-box .close-btn:hover {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.transform-in,
.transform-out {
    display: block;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.transform-in {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}

.transform-out {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
}



.btn-hover-quote {
    font-family: 'Baloo Bhaijaan 2', cursive;
    width: 40vmin;
    font-size: 3vmin;
    cursor: pointer;
    margin: 20px;
    height: 8vmin;
    font-weight: 600;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    position: relative;
    z-index: 7;
    margin-top: 40vh;
    border-radius: 50px;

}




.btn-hover-quote:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover-quote:focus {
    outline: none;
}


.btn-hover-quote.colorpro-1 {
    background-image: linear-gradient(to right, #ff83ef, #ffe231, #3ff1f9, #3483f9);
    box-shadow: 0 4px 15px 0 rgba(6, 80, 102, 0.699);

}

.SigninAll-quote {
    font-size: 5vh;
    position: absolute;
    background-color: #ffffff;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    height: 65vh;
    z-index: 5;
    /* transition: all 0.7s ease 0.3s; */
}


.ko-quote {
    color: #000000a8;
    z-index: 5;
    font-size: 2.5vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;
    position: absolute;
    text-align: center;
    justify-content: center;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
}

.chain-quote {
    position: relative;
    width: 50vmin;
    height: 25vh;
    object-fit: contain;
    margin-top: 5vh;
}

@media only screen and (width<=800px) {
    .btn-hover-quote {
        width: 50vmin;
        font-size: 5vmin;
        height: 10vmin;

    }

    .ko-quote {

        font-size: 3vmin;
        width: 60%;
        text-align: center;
        justify-content: center;
    }
}