#enginfo {
    width: 100%;
    overflow: visible;
    z-index: 1;
    /* margin-top: 5em; */
    height: 100vh;

}

p {
    color: #ffffff;
}

span {
    color: #ffffff;

}


.home-content {

    z-index: 0;
    position: relative;
}




/* #videocircle {
    top: 0%;
    position: absolute;
    border-radius: 80pc;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    height: 30vh;

}


.cut {
    top: 0%;
    position: absolute;
    border-radius: 80pc;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    height: 40vh;
} */

.vidd {
    position: absolute;
    top: -23%;
    z-index: 3;


}

#videocircle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 30vh;
    border-radius: 50%;

}

.cut {
    height: 42vh;

}

@media only screen and (width<=870px) {
    #videocircle {
        height: 25vh;
    }

    .cut {
        height: 35vh;
    }
}


.theLine {
    stroke-width: 10px;

    filter: drop-shadow(0px -5px 10px rgb(255, 255, 251));
    stroke: rgb(245, 95, 237);
}

.relatedLine {
    stroke-width: 10px;

    filter: drop-shadow(0px -5px 10px rgb(255, 255, 251));
    stroke: rgb(255, 174, 0);
}

.relatedLinenew {
    stroke-width: 10px;

    filter: drop-shadow(0px -5px 10px rgb(255, 255, 251));
    stroke: rgb(255, 174, 0);
}

.relatedLine2 {
    stroke-width: 10px;

    filter: drop-shadow(0px -5px 10px rgb(255, 255, 251));
    stroke: rgb(255, 255, 255);
}

.relatedLinebottom {
    stroke-width: 10px;

    filter: drop-shadow(0px -5px 10px rgb(255, 255, 251));
    stroke: rgb(255, 174, 0);
}

.relatedLinebottom2 {
    stroke-width: 10px;

    filter: drop-shadow(0px -5px 10px rgb(255, 255, 251));
    stroke: rgb(255, 255, 255);
}

.ball {
    fill: rgb(253, 217, 56);
    visibility: hidden;
    filter: drop-shadow(0px 2px 5px rgb(230, 62, 207));
}

.ball01 {
    filter: drop-shadow(0px -5px 10px rgb(252, 126, 252));
}

.line {
    fill: none;
    stroke: white;
    stroke-width: 2px;
}

.star1 {
    filter: drop-shadow(0px 2px 5px rgb(4, 255, 213));
    color: white;
    opacity: 100%;
}

.info2 {
    filter: drop-shadow(0px 2px 5px rgb(255, 251, 6));
    color: rgb(255, 255, 255);
}

.info3 {
    filter: drop-shadow(0px 2px 5px rgb(255, 87, 241));
}

.listening {
    filter: drop-shadow(0px 2px 5px rgb(4, 255, 213));
    color: #ffffff;
    font-size: 1.5em;
    clip-path: polygon(0% 0%, 0% 0%, 0% 125%, 0% 100%);
    transform: translateX(15px);
    position: absolute;
    overflow: hidden;
}

.myVid {
    width: 13vmax;
    margin-top: 0vmax;
    visibility: hidden;
    position: absolute;
}

body {
    background-color: #050313;
}

.reading {
    filter: drop-shadow(0px 2px 5px rgb(255, 251, 6));
    color: #ffffff;
    font-size: 1.5em;
    clip-path: polygon(0% 0%, 0% 0%, 0% 125%, 0% 100%);
    transform: translateX(15px);
    position: absolute;
    overflow: hidden;
    text-align: center;
}

.grammer {
    filter: drop-shadow(0px 2px 5px rgb(255, 87, 241));
    color: #ffffff;
    font-size: 1.5em;
    clip-path: polygon(0% 0%, 0% 0%, 0% 125%, 0% 100%);
    transform: translateX(15px);
    position: absolute;
    overflow: hidden;
}

.choose {
    width: 20vmax;

    /* height: 22vmax; */
    border-radius: 2pc;
    object-fit: cover;
    left: 25vmax;
    margin-top: -20vmax;
    justify-content: center;
    /* opacity: 30%; */
    position: absolute;
}

.choose2 {
    width: 10vmax;
    height: 10vmax;
    /* height: 22vmax; */
    border-radius: 2pc;
    object-fit: cover;
    margin-left: -35vmax;
    margin-top: 30vmax;
    justify-content: center;
    /* opacity: 30%; */
    position: absolute;
}
