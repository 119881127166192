#moviestypes {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px;
  z-index: -1;
  align-items: center;
  justify-items: center;
}

#types-section {
  height: 100vh;
  grid-area: 1 / 1 / 2 / 2;
  z-index: -1;
  clip-path: circle(100% at 50% 50%);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#types-section .inner {
  max-width: 900px;
}

.second-inner {
  margin-left: 40%;
}

.movies-types-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.movies-types-list li + li {
  margin-top: 5vh;
}

.movies-list-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Baloo Bhaijaan 2', cursive;
  width: 45vmin;
  height: 10vmin;
  background-color: #d0d0d0;
  box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.596);
  border-radius: 10vmax;
}

.se {
  top: 40vh;
  margin-left: -40%;
  width: 30vh;
  height: 7vh;
  font-weight: 900;
  font-size: 7vmin;
  position: absolute;
  color: #fff;
  filter: drop-shadow(0.4vmin 0.6vmin 0 rgba(252, 109, 245, 0.678)) drop-shadow(0 0.8vw 0 rgba(3, 15, 17, 0.705));
}

.se2 {
  width: 100%;
  text-align: center;
  justify-content: center;
  left: 0;
  right: 0;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-weight: 500;
  font-size: 8vmin;
  position: absolute;
  color: rgba(255, 254, 254, 0.877);
}

.avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 100px;
  z-index: 999;
  margin-right: 2rem;
}

.im, .horror, .animation, .comedy2 {
  position: absolute;
  filter: drop-shadow(0px -5px 10px rgb(0, 0, 0));
}

.im {
  width: 27vmin;
  height: 15.5vmin;
  margin-left: 20vmin;
  margin-top: -5.7vmin;
}

.horror {
  width: 25vmin;
  margin-left: 18vmin;
  margin-top: -3vmin;
}

.animation {
  width: 38vmin;
  margin-left: 11vmin;
  margin-top: 2.3vmin;
}

.comedy2 {
  width: 18vmin;
  margin-left: 25vmin;
  margin-top: 0.5vmin;
}

.descr {
  font-size: 2.5vmin;
  color: #ffffff;
}

@media only screen and (max-width: 550px) and (min-height: 450px) {
  .movies-list-items {
    cursor: pointer;
    margin-top: 12vh;
    width: 55vmin;
    height: 13vmin;
    filter: drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.596));
  }
  .descr {
    font-size: 3.5vmin;
  }
  .second-inner {
    justify-content: center;
    position: relative;
    margin: auto;
    padding: 0;
  }
  .se {
    width: 100%;
    text-align: center;
    top: 2vh;
    font-weight: 900;
    font-size: 6.5vmin;
  }
  .im {
    width: 30vmin;
    height: 17vmin;
    margin-top: -3.7vmin;
  }
  .horror {
    width: 30vmin;
    margin-top: -2vmin;
  }
  .animation {
    width: 47vmin;
    margin-top: 3.4vmin;
  }
  .comedy2 {
    width: 20vmin;
    margin-top: 0.5vmin;
  }
}

@media only screen and (max-width: 280px) {
  .movies-list-items {
    cursor: pointer;
    margin-top: 0vh;
    width: 50vmin;
    height: 10vmin;
    filter: drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.596));
  }
  .second-inner {
    justify-content: center;
    margin: auto;
    padding: auto;
  }
  .se {
    width: 100%;
    text-align: center;
    top: -8vh;
    font-weight: 900;
    font-size: 4vmin;
  }
  .im {
    width: 27vmin;
    height: 15.5vmin;
    margin-left: 20vmin;
    margin-top: -5.7vmin;
  }
  .horror {
    width: 25vmin;
    margin-left: 18vmin;
    margin-top: -3vmin;
  }
  .animation {
    width: 38vmin;
    margin-left: 11vmin;
    margin-top: 2.3vmin;
  }
  .comedy2 {
    width: 24vmin;
    margin-left: 17vmin;
    margin-top: 0.5vmin;
  }
}

.descriptiont {
  justify-content: center;
  margin: auto;
  padding: 0;
  width: 80%;
}

.description {
  justify-content: center;
  margin: auto;
  padding: 0;
  width: 50%;
  text-align: center;
}

.descall {
  font-size: 2vmin;
  color: #181717da;
}

#alltypes {
  justify-content: center;
  margin: auto;
  padding: 0;
  margin-top: 6vh;
  width: 18vmin;
  height: 5vmin;
}
