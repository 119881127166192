@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2&display=swap);

.parallax {
  position: relative;
  height: 110vmin;
  /* margin-top: -10vh; */
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;

  /* transform: skewY(5deg); */
}


.image-gridu {
  position: relative;
  background-color: black;
  height: 80vh;
  width: 100%;
  overflow: hidden;
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0;
}


.wrapp {
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  grid-row-gap: 1.2rem;
  align-items: center;
  margin-bottom: 50px;
  position: absolute;
  width: 100%;
  margin-left: 5%;
  /* justify-content: center;
  margin: auto;
  margin-left: 0;
  margin-right: 0; */
  top: 15%;
}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vmin;
  width: 30vmin;
  margin-right: 20px;
  background-color: wheat;
}

.clouds {
  position: absolute;
  filter: drop-shadow(15px 0 0 rgb(255, 255, 255)) drop-shadow(0 -5px 0 rgb(255, 232, 21));

  /* background-color: rgb(25, 94, 155); */
  width: 100%;
  z-index: 0;
  margin-top: 3%;
}

.moviephoto {
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.199) 10%, rgb(240, 220, 220) 60%);
  position: absolute;
  width: 100%;
  height: 110%;
  top: -10%;
  object-fit: cover;
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0%;
  background-size: cover;
  background-position: 50%;
}

#text-anim {
  position: absolute;
  font-size: 5vmin;
  top: 10%;
  justify-content: center;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  font-family: 'Baloo Bhaijaan 2', cursive;
  color: #6666669f;
  /* background-color: #facc34; */
  height: 8vmin;
  padding-top: 0.5%;
  /* filter: drop-shadow(0px 4px 2px rgba(255, 254, 254, 0.89)); */
  /* -webkit-text-stroke: 0.1vmin rgb(71, 56, 29); */

}

#enginfoo {
  width: 100%;
  position: absolute;
  overflow: visible;
  z-index: 1;
  top: 15vmin;
  height: 100vmin;

}

#enginfoo2 {
  width: 100%;
  position: absolute;
  overflow: visible;
  z-index: 1;
  top: 15vmin;
  height: 100vmin;

}

#enginfoobottom {
  width: 100%;
  position: absolute;
  overflow: visible;
  z-index: 1;
  top: 62vmin;
  height: 100vmin;

}

h1 div {
  display: inline-block;
}

.image-gridf {
  position: relative;
  height: 90vmin;
  width: 100%;
  overflow: hidden;
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0;
  bottom: 0%;
  box-shadow: 0 2rem 4rem #193118a6;
  z-index: 1;
  background-color: #ffffff;
}

.limg {
  /* mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.199) 10%, rgb(240, 220, 220) 60%); */
  position: absolute;
  width: 100%;
  height: 150%;
  object-fit: cover;
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0%;
  background-size: cover;
  background-position: 50%;
}




.h1movie {
  justify-content: center;
  font-family: 'Baloo Bhaijaan 2', cursive;
  text-align: center;
  color: rgba(255, 255, 255, 0.774);
  text-shadow: 1px 1px 6px black;
  z-index: 1;
  font-size: 5vmin;


}

.h1moviecont {
  justify-content: center;
  font-family: 'Baloo Bhaijaan 2', cursive;
  text-align: center;
  color: rgba(255, 255, 255, 0.774);
  text-shadow: 1px 1px 6px black;
  z-index: 1;
  font-size: 5vmin;
  /* margin-top: -13vmin; */
  filter: drop-shadow(0px 1px 2vmin rgb(255, 255, 255));
  position: absolute;
  left: 0%;
  right: 0;
  padding: 0%;
  top: 43%;
  width: 80%;
  left: 0;
  right: 0%;
  text-align: center;
  margin: auto;
}

.png-cover {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0%;
  padding: 0%;
  justify-content: center;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  filter: drop-shadow(0px 10px 4px rgba(31, 40, 44, 0.671));

}

.movie-image {
  position: absolute;
  top: -30vmin;
  height: 40vmin;
  width: 45vmin;
  object-fit: contain;
  filter: drop-shadow(0px 10px 4px rgba(31, 40, 44, 0.671));
  justify-content: center;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.card_cat {
  top: 20vmin;
  position: absolute;
  width: 80vmin;
  display: flex;
  justify-content: space-between;
  margin-right: -3vmin;
  padding: 1vmin;
  font-weight: bold;
  font-size: 3vmin;
  color: rgb(255, 255, 255);
  letter-spacing: 0.3vmin;
  font-family: 'Baloo Bhaijaan 2', cursive;
  -webkit-text-stroke: 0.1vmin rgba(240, 239, 238, 0.637);
}


p span {
  display: inline-block;

}

.PGz {
  top: 20vmin;
  position: absolute;
  width: 80vmin;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-evenly; */
  margin-right: -3vmin;
  padding: 1vmin;
  font-weight: bold;
  font-size: 3vmin;
  /* filter: drop-shadow(0px 0.3vmin 0.3vmin rgb(124, 102, 1)); */
  color: rgb(255, 255, 255);
  letter-spacing: 0.3vmin;
  font-family: 'Baloo Bhaijaan 2', cursive;
  -webkit-text-stroke: 0.1vmin rgba(240, 239, 238, 0.637);
}



.cropped {
  position: absolute;
  margin: auto;
  top: 33%;
  left: 0%;
  right: 0%;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 50vmin;
  width: 90vmin;

}

.video2 {
  height: 100%;
  width: 96%;
  margin: auto;
  left: 0%;
  right: 0%;
  justify-content: center;
  position: absolute;


}

.cropped-img {
  height: 100%;
  width: 98%;
  margin: auto;
  left: 0%;
  right: 0%;
  justify-content: center;
  position: absolute;
  object-fit: cover;

}


.video-container {
  height: 97%;
  width: 96%;
  margin: auto;
  left: 0%;
  right: 0%;
  bottom: 0%;
  top: 0%;
  justify-content: center;
  position: absolute;
  border-radius: 4px;
}

.video-container .video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.video-container video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;

}

.play-button-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  pointer-events: none;
}

.play-button-wrapper #circle-play-b {
  cursor: pointer;
  pointer-events: auto;
}

.play-button-wrapper #circle-play-b img {
  width: 26vmin;
  height: 26vmin;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.9;
  right: 3%;
  position: relative;
}

.movie-desc {
  position: absolute;
  color: rgb(145, 143, 143);
  width: 80%;
  font-size: 1.8vmin;
  text-align: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 78vmin;
  display: inline-block;
  font-family: 'Baloo Bhaijaan 2', cursive;
  width: 50%;
  font-weight: 500;
  letter-spacing: 0.05vmin;
  
}

.liine {
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 15vmin;
  height: 25vmin;
}

.line-png {
  width: 100vmax;
  position: absolute;
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0;
  /* opacity: 50%; */
  height: 100%;
  /* rotate: 5deg; */
}


@media only screen and (width<=550px) and (height<940px) {
  .movie-image {
    position: absolute;
    top: -38vmin;
    height: 50vmin;
    width: 60vmin;

  }


  .movie-desc {
    width: 85%;
    font-size: 3vmin;
    top: 12vmin;

  }

  .card_cat {
    font-size: 3.8vmin;
  }


  .starmovie {
    width: 4vmin;
    margin-top: 1vmin;
    margin-left: -4vmin;
  }

  .h1movie {

    font-size: 7vmin;
  }



}



/* ------------------------------------------- */
.image-gridu-clouds {
  position: relative;
  height: 90vmin;
  width: 100%;
  /* overflow: hidden; */
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0;
  /* margin-top: 11% */
}


.menu {
  overflow: hidden;
  cursor: -webkit-grab;
  cursor: grab;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 40vmin;
  margin-top: 28vmin;

}

.menu.is-dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.menu--wrapper {
  /* counter-reset: count; */
  z-index: 1;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
}

.menu--item {
  /* counter-increment: count; */
  z-index: 1;
  top: 0;
  left: 0;
  width: 30vw;
  height: 100%;
  padding: 0 1.5vw;
}

@media (max-width: 767px) {
  .menu--item {
    width: 40vw;
    height: 40vw;
  }
}

.menu--item figure {
  z-index: 1;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  width: 100%;
  height: 40vmin;
  overflow: hidden;
  pointer-events: none;
  transform-origin: center;
}

.menu--item figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: middle;
  transform-origin: center;

}

.menu--item figure:after {
  content: "";
  display: block;
  position: absolute;
  height: 70%;
  width: 100%;
  z-index: 1;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.911));
}




.item-desc-text {
  font-size: 4vmin;
}

.item-desc {
  padding: 0 1.5vh 12px;
  color: #fff;
  z-index: 1;
  overflow: hidden;
  /* transform: translateY(calc(100% - 6vh));
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; */
  position: absolute;
  width: 100%;
  height: 100%;
  color: #ffffff;
  filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
  font-family: 'Baloo Bhaijaan 2', cursive;
  -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
  bottom: 0;
  height: 8vh;
}

@media only screen and (width<=950px) {

  .item-desc {
    height: 6vh;
  }

}