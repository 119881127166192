/* @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css); */
.home-content {

  z-index: 0;
  position: relative;
}




#choose1 {
  width: 100%;
  height: 20vmax;
background-color: aliceblue;

}



:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1140;
  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
      (var(--fluid-max-width) - var(--fluid-min-width))
  );
}

@media screen and (min-width: 1140px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}
:root {
  --f-0-min: 18;
  --f-0-max: 20;
  --step-0: calc(
    ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
      var(--fluid-bp)
  );
  --f-1-min: 20;
  --f-1-max: 24;
  --step-1: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );
}




p {
  line-height: 1.35;
}




h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.flow--lg > * + * {
  margin-top: 2em;
}

.flow > * + * {
  margin-top: 1em;
}

.title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50vh;
}




.intro,
h2,
h3 {
  font-size: var(--step-1);
  font-weight: 500;
}

.text-container {
  position: relative;
}

.text-container p {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: transparent;
}

.text-container p:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: white;
}

.image-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0.2rem;
  grid-row-gap: 0.2rem;
  width: 70vw;
  margin: 0 auto;
  padding-top: 40vh;
}
.image-grid .image_cont {
  position: relative;
  aspect-ratio: 1/1;
  overflow: hidden;
}
.image-grid img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 150%;
  object-fit: cover;
}
.image-grid .image_cont:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}
.image-grid .image_cont:nth-child(2) {
  grid-column: 3;
  grid-row: 2;
}
.image-grid .image_cont:nth-child(3) {
  grid-column: 2;
  grid-row: 3;
}

.parallax-images {
  margin-top: 10vh;
  padding: 10rem 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 20vh;
  align-items: center;
  justify-items: center;
}
.parallax-images .image_cont {
  position: relative;
  height: 80vh;
  overflow: hidden;
}
.parallax-images img {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  height: 140%;
  width: 100%;
  object-fit: cover;
}
.parallax-images .parallax-text {
  grid-column: 2;
  grid-row: 1;
}
.parallax-images .image_cont:nth-child(2) {
  grid-column: 1 / span 1;
  grid-row: 1;
  width: 100%;
}
.parallax-images .image_cont:nth-child(3) {
  grid-column: 2 / span 1;
  grid-row: 2;
}

.spacer {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stagger {
  font-size: 8vw;
}


.staggered {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 4rem;
}
.staggered h3 {
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: clamp(16px, 6vw, 80px);
  letter-spacing: 0.03em;
}
.staggered_text {
  flex: 1 1 300px;
}
.staggered_demo {
  flex: 1 1 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-slab {
  position: relative;
  height: 500px;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}
.parallax-slab {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 180%;
  object-fit: cover;
}

.v-center {
  display: flex;
  align-items: center;
}

