#sign-section {
    position: relative;
    height: 35vmin;
    background-color: rgb(8 21 32);
    width: 100%;
    box-shadow: 0px 1vmin 5vmin 1vmin rgb(169, 172, 30);
}

#subsc-section {
    position: relative;
    height: 35vmin;
    background-color: rgb(8 21 32);
    box-shadow: 0px 1vmin 5vmin 1vmin rgb(169, 172, 30);
    width: 100%;
    
}

.GifImages {
    position: relative;
    height: 25vmin;
    margin-right: -5vmin;
    margin-top: 5vmin;
    max-width: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    justify-content: right;
    float: right;
}
.GifImages8 {
    position: relative;
    height: 25vmin;
    margin-top: 5vmin;
    margin-left: 5vmin;
    max-width: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    
}
.subscVideo {
    position: relative;
    overflow: hidden;
    padding-right: 8vmin;

}
.signVideo {
    position: relative;
    overflow: hidden;
    padding-right:8vmin;  
    
}
.box-1 {
    position: relative;
    height: 10vh;
    width: 100%;
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-size: 48pt;
    background: black;
    color: #433C3D;
    text-align: center;
}

.bars {
    display: grid;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    padding: 0%;
    position: absolute;
    width: 100vmin;
    left: 0;
    right: 0;
    padding: 0;
    
    grid-template-columns: repeat(2, 1fr);
}

.contentv {
    color: aliceblue;
    position: relative;
    border-left: solid 1px white;
    padding: 0vmin 6vmin;
    margin-top: 10vmin;
    font-size: 2.2vmin;
    width: 100%;
    list-style-type: none;
    left: 0%;
    font-family: 'Baloo Bhaijaan 2', cursive;
}

.signtext {
    color: rgb(250, 235, 98);
    font-size: 3vmin;

}

.content2 {
    color: aliceblue;
    position: relative;
    border-right: solid 1px white;
    padding: 0vmin 6vmin;
    margin-top: 7vmin;
    font-size: 3vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;
    width: 100%;
    list-style-type: none;
    left: 1%;

}

.subsctext {
    color: rgb(250, 235, 98);
    font-size: 3vmin;
    text-align: right;
}

.flo {
    text-align: right;
}

.flo2 {

    text-align: right;

}

/* ---------------------------- */

.hint {
    position: absolute;
    width: 100%;

}

.hint__icon {
    position: absolute;
    margin-left:10vmin;

    top: 0vmin;
    width: 4.5vmin;
    height: 4.5vmin;
    cursor: pointer;
    transition: 360ms ease;
    color: #B0BEC5;
    border: 1px solid #B0BEC5;
    border-radius: 50%;
    font-size: 3vmin;
}

.hint__icon::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.3vmin;
    margin-left: -0.7vmin;
    content: "?";
}

.hint__icon_active {
    color: #dfad09;
    border-color: #dfad09;
}

.hint__content {
    position: absolute;
    margin: auto;
    padding: 0%;
    justify-content: center;
    left: 0vmin;
    right: 0vmin;
    z-index: 1;
    /* top: -5vmin; */
    visibility: hidden;
    width: 70vmin;
    padding: 12px 16px;
    transition: 360ms ease;
    opacity: 0;
    color: #263238;
    border: 1px solid #90A4AE;
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0 2px 6px 0 rgba(38, 50, 56, 0.4);
}

.hint__content_active {
    visibility: visible;
    transform: translateY(38px);
    opacity: 1;
    
}

.hint__text {
    margin: 0;
    color: #263238b6;
    font-size: 2.5vmin;

}

.hint__text img {
    width: 4vmin;
}

.hint__text li {
    padding: 2%;

    list-style-type: none;
}