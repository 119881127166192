.favmovie {
    position: relative;
    background-size: cover;
    font-family: 'Baloo Bhaijaan 2', cursive;
    height: 100vh;
    width: 100%;
    color: aliceblue;
}

.container-fluid {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slider-wrapper {
    padding: 25px;
    width: 100%;
    overflow: hidden;
}

.slides-container {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: center;
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin-right: 2vmin;
}

.slide img {
    width: 20vmax;
    height: 30vmax;
    margin: 0.3vmin;
    border-radius: 10px;

}

.mcent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4vmin;
}
.mcent2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
    background-color: rgb(255, 196, 0);
    border-radius: 50px;
    
}
.mcentspan{
    color: rgb(37, 33, 33);
    font-size: 3vmin;
    margin: 2vmin;

}