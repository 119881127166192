.single-item-yellow,
.single-item-green,
.single-item-red,
.single-item-blue {
    width: 38vmin;
    height: 20vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bd0f0f00;
    margin: 1.8vmin;
    margin-top: 2vmin;
    border-radius: 3vmin;
    color: #888;
    background-position: 50%;

}

.single-item-yellow:hover {
    box-shadow: -1.5vmin 0 3vmin -1vmin rgb(250, 218, 40), 1.5vmin 0 3vmin -1vmin #fff019;
}

.backgrounds-yellow {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.background-yellow {
    --offset: 0;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #4bffff, #d2fcfc, #faefc1, #f7e709);
    transform: scale(0);
    transition: all 0.4s ease 0s;
    border-radius: 5vmin;
}

.background2-yellow {
    --offset: 10%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #5ffcfc, #d5f7fd, #fff3cd, #fffc65);
    transform: scale(0);
    transition: all 0.4s ease 0.01s;
}

.background3-yellow {
    --offset: 20%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #79fafa, #ffffff94, #ffffff8e, #fff783);
    z-index: 2;
    transition: all 0.4s ease 0.02s;
}

.background4-yellow {
    --offset: 30%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #aaf8f8, #ffffff8e, #ffffff96, #fff9aa);
    z-index: 3;
    transition: all 0.4s ease 0.03s;
}

.background5-yellow {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #cdf8f8, #ffffff70, #ffffff8a, #fff9aa);
    z-index: 4;
    transition: all 0.4s ease 0.04s;
}

.background6-yellow {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: white;
    z-index: 5;
    transition: all 0.7s ease 0.06s;
}


.background-yellow.state-show {
    transform: scaleX(1.1);

}

.background6-yellow.state-show {
    transform: scale(6);

}

.norepeat-all {
    position: absolute;
    width: 35vmin;
    height: 18vh;
    background-position: 50%;
    overflow: hidden;

}

.norepeat-yellow {
    position: absolute;
    object-fit: cover;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* margin-left:-20% ; */
}

.overlay-yellow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 217, 0, 0.384);

}

.overlaysolved-yellow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000008f;
}

.button-text-yellow {
    justify-content: center;
    text-align: center;
    font-size: 7vh;
    color: #ffffff;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
    font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}

.noquestions {
    justify-content: center;
    text-align: center;
    color: #8fcef0;
    font-family: 'Baloo Bhaijaan 2', cursive;
    position: absolute;
    margin: auto;
    width: 100%;
    top: 30vh;
}
.uploadImage,
.uploadImageyellow,
.uploadImageblue,
.uploadImagebluepro,
.uploadImagered,
.uploadImageredpro,
.uploadImagegreen,
.uploadImagegreenpro{
    position: absolute;
    right: 7%;
    width:50px;
    height: 50px;
    font-size: 10px;
    top: 15%;

}
.uploadbutton, 
.uploadbuttonyellow,
.uploadbuttonblue,
.uploadbuttonbluepro,
.uploadbuttonred,
.uploadbuttonredpro,
.uploadbuttongreen,
.uploadbuttongreenpro
{
    width:50px;
    height: 50px;
    font-size: 10px;
    position: absolute;
    top: 15%;
    left: 35%;
}
.noquestionsfree {
    justify-content: center;
    text-align: center;
    color: #8fcef0;
    font-family: 'Baloo Bhaijaan 2', cursive;
    position: absolute;
    margin: auto;
    width: 100%;
    top: 35vh;
}

.noquestionsh1 {
    font-size: 5vmin;

}

#quiz-container-yellow {
    position: relative;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    justify-content: center;
    width: 100%;
    height: 55vh;
    background-color: #f1ce05;

}



.videoq-yellow {
    position: absolute;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    width: 90%;
    height: 30vh;
    object-fit: cover;
    border-radius: 3vmin;
}

.i-amphtml-story-quiz-container-yellow {
    align-self: start;
    --correct-color: #5BBA74 !important;
    --correct-color-shaded: #2D8E42 !important;
    --incorrect-color: #F34E4E !important;
    --incorrect-color-shaded: #cc4141 !important;
    --accent-color: #f1ce05;
    background: var(--accent-color) !important;
    border-radius: 5vmin !important;
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: 6vh;
    height: 33vh;

}

.question-head-yellow {
    position: absolute;
    font-size: 2.5vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    color: black;
    text-align: center;
    filter: drop-shadow(0px 2px 4px rgb(24, 23, 23));
    font-family: 'Baloo Bhaijaan 2', cursive;
    letter-spacing: 0.2vmin;
    -webkit-text-stroke: 0.1vmin rgba(255, 255, 255, 0.966);
    top: 1vh;
}

.question-text-yellow {
    position: absolute;
    font-size: 2.6vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0));
    font-family: 'Baloo Bhaijaan 2', cursive;
    /* letter-spacing: 0.2vmin; */
    /* -webkit-text-stroke: 0.1vmin rgb(0, 0, 0); */
    text-align: center;
    top: -19%;
    overflow: visible;
    z-index: 1;
    width: 95%;
    color: rgba(39, 39, 39, 0.699);
}

#question-text-yellow-show {
    position: absolute;
    font-size: 3vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    /* filter: drop-shadow(0px 2px 4px rgb(0, 0, 0)); */
    font-family: 'Baloo Bhaijaan 2', cursive;
    /* letter-spacing: 0.2vmin; */
    color: rgba(39, 39, 39, 0.699);
    text-align: center;
    width: 90%;
    margin-top: 36.5vh;
    overflow: visible;
    z-index: 1;
}

.solved-yellow {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    left: 0%;
    top: -5vmin;
    width: 10vmin;
    height: 10vmin;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
}



.video-options-yellow {
    color: rgb(80, 79, 79);
    text-align: center;
    font-size: 2.3vh;
    /* filter: drop-shadow(0px 1px 7px rgb(255, 253, 253)); */
    -webkit-text-stroke: 0.1vmin rgba(0, 0, 0, 0.253);
}

.i-amphtml-story-quiz-option-container-yellow {
    display: flex !important;
    flex-direction: column;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 5vmin !important;
    padding: 1vmin 2vmin 1vmin 0.5vmin !important;
    position: absolute;
    width: 100%;
    height: 35vh;
    top: 43vh;
}

.answer__item-yellow {
    background-color: #f6f6f6;
    display: block;
    position: relative;
    cursor: pointer;
    height: 8vh;
    width: 98.5%;
    border-radius: 8vmin !important;
    /* padding: 1vmin 2vmin 2vmin 3vmin !important; */
    /* background-color: inherit !important; */
    margin: 0.85vh;
    border: solid 1px #DADCE0 !important;
    font-size: 2vmin !important;
    line-height: 4vmin !important;
}

:valid .answer__item-yellow--is-correct {
    background-color: rgba(61, 181, 64, 0.6)
}

:checked+.answer__item-yellow {
    color: #ffffff;
    background-color: #c70000
}

:checked+.answer__item-yellow--is-correct {
    background-color: #3db540;
}

:checked+.answer__item-yellow .answer__icon {
    display: inline-block
}

:valid+.answer__item-yellow {
    pointer-events: none
}

:checked+.answer__item-yellow--is-correct {
    counter-increment: quiz-score
}

.Signin {
    font-size: 5vh;
    position: absolute;
    color: #2D8E42;
    top: 40%;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 5;
    /* transition: all 0.7s ease 0.3s; */

}



.SigninAllfree {
    font-size: 5vh;
    position: absolute;
    background-color: #2D8E42;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    /* margin-top:-8vh; */
    z-index: 5;
    /* transition: all 0.7s ease 0.3s; */

}

@media only screen and (width<=1000px) {
    .question-text-yellow {
        font-size: 3vmin;
    }

    .video-options-yellow {
        font-size: 3vmin;
    }

    #question-text-yellow-show {
        font-size: 3vmin;

    }
}

.givemeid {
    top: -2%;
    border-radius: 12px;

}

@media only screen and (width<=550px) and (height<920px) {

    .single-item-yellow,
    .single-item-green,
    .single-item-red,
    .single-item-blue {
        margin-top: 2vh;
    }

    .Signin {
        font-size: 4vh;
    }

    .video-options-yellow {
        font-size: 2vh;
    }

    .solved-yellow {
        position: absolute;
        top: -7vmin;
        width: 14vmin;
        height: 14vmin;
    }

    #question-text-yellow-show {
        position: absolute;
        font-size: 2vh;
        /* width: 100%; */
    }

    .question-head-yellow {
        font-size: 2vh;
    }

    .question-text-yellow {
        position: absolute;
        font-size: 2vh;
        /* width: 100%; */

    }

}

@media only screen and (width<=300px) {
    .question-text-yellow {
        font-size: 1vh;
    }

    .video-options-yellow {
        font-size: 1vh;
    }

    .question-head-yellow {
        font-size: 1vh;
    }

    #question-text-yellow-show {
        font-size: 1vh;

    }
}

input[type="radio"] {
    display: none;

}

#correct {
    background-color: rgba(61, 181, 65, 0.849);
}

#wrong {
    background-color: #e78282;
}

#none {
    background-color: rgba(255, 217, 4, 0.808);

}

.answer__reveal-text {
    display: none;
}

:valid .answer__reveal-text {
    display: block
}


#answered {
    outline: none;
    pointer-events: none;
    border: 0;

}

*:focus {
    outline: 0 !important;
}


form {
    counter-reset: quiz-score
}


.score:after {
    content: counter(quiz-score) "/" attr(data-question-count)
}

/* show score once quiz has been completed */
.message {
    display: none;
}

form:valid .message {
    display: block
}




.form-yellow {
    margin: auto;
    padding: 2% 3%;
    left: 0;
    right: 0;
    margin-top: 1vh;
    position: ab;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 2fr;
    font-size: 2.5vh;
    justify-content: center;
    border: 1px solid rgb(145, 144, 144);
    border-radius: 5vh;
    font-family: 'Baloo Bhaijaan 2', cursive;
    height: 5vh;
    width: 90%;
}

.create-button {
    background-color: #408a23;
    display: block;
    color: white;
    margin: auto;
    position: absolute;
    cursor: pointer;
    height: 5vh;
    text-align: center;
    bottom: 2vh;
    width: 40%;
    border-radius: 8vmin !important;
    padding: 0%;
    justify-content: center;
    left: 0;
    right: 0;
    border: solid 1px #DADCE0 !important;
    font-size: 2.5vh;
    color: #ffffff;
    filter: drop-shadow(0px 1.1vmin 0.5vmin rgba(3, 3, 3, 0.589));
    font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}

.remove-button {
    position: absolute;
    z-index: 10;
    width: 5vmin;
    height: 5vmin;
    border: none;
    right: 1%;
    bottom: 39vh;
    font-weight: 300;
    background-color: #ffffff;
    border-radius: 50pc;
}

.remove-button-gr {
    position: absolute;
    z-index: 10;
    width: 5vmin;
    height: 5vmin;
    border: none;
    right: 1%;
    bottom: 32vh;
    font-weight: 300;
    background-color: #ffffff;
    border-radius: 50pc;
}








.btn-hover {

    font-family: 'Baloo Bhaijaan 2', cursive;
    width: 40vmin;
    font-size: 3vmin;
    cursor: pointer;
    margin: 20px;
    height: 8vmin;
    font-weight: 600;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    z-index: 7;
    margin-top: 40vh;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover-free {
    position: absolute;
    font-family: 'Baloo Bhaijaan 2', cursive;
    width: 40vmin;
    font-size: 3vmin;
    cursor: pointer;
    margin: auto;
    padding: 0%;
    justify-content: center;
    left: 0;
    right: 0;
    height: 8vmin;
    font-weight: 600;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    z-index: 7;
    margin-top: 45vh;
    border-radius: 50px;
    /* transition: all 0.2s ease 0.3s; */

}

.btn-hover-free:hover {
    background-position: 100% 0;
    /* moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; */
}

.btn-hover-free:focus {
    outline: none;
}

.btn-hover-free.color-1 {
    background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(6, 80, 102, 0.699);

}

.btn-hover:hover {
    background-position: 100% 0;
    /* moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; */
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-1 {
    background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(6, 80, 102, 0.699);

}

.btn-hover.colorpro-1 {
    background-image: linear-gradient(to right, #ff83ef, #ffe231, #3ff1f9, #3483f9);
    box-shadow: 0 4px 15px 0 rgba(6, 80, 102, 0.699);

}

.SigninAll {
    font-size: 5vh;
    position: absolute;
    background-color: #ffffff;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    height: 82vh;
    margin-top: -8vh;
    z-index: 2;
    /* transition: all 0.7s ease 0.3s; */
}

.SigninAllfree {
    font-size: 5vh;
    position: absolute;
    background-color: #ffffff;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    height: 82vh;
    margin-top: 0vh;
    z-index: 5;
    /* transition: all 0.7s ease 0.3s; */
}

.ko {
    color: #000000a8;
    z-index: 5;
    font-size: 2.5vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;
    position: absolute;
    text-align: center;
    justify-content: center;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
}
.kooffer {
    color: #eb1313;
    z-index: 5;
    font-size: 2.5vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;
    position: absolute;
    text-align: center;
    justify-content: center;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    bottom:5vh;
}
.chain {
    position: relative;
    width: 50vmin;
    height: 25vh;
    object-fit: contain;
    margin-top: 15vh;

    /* height: 40%; */
}

@media only screen and (width<=800px) {
    .btn-hover {
        width: 50vmin;
        font-size: 5vmin;
        height: 10vmin;

    }

    .btn-hover-free {
        width: 50vmin;
        font-size: 5vmin;
        height: 10vmin;

    }

    .ko {

        font-size: 3vmin;
        width: 60%;
        text-align: center;
        justify-content: center;
    }
    .kooffer {

        font-size: 3.5vmin;
        width: 60%;
        text-align: center;
        justify-content: center;
    }
}