.overlayloader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
  overflow: hidden;
}

.overlayloader.first {
  background: #fcc419;
}

.overlayloader.second {
  background: #fcc419;
  left: 33.33%;
}

.overlayloader.third {
  background: #fcc419;
  left: 66.66%;
}

/* --------------------- */
.Allmoviestypes{
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
.epic-slider {
  display: flex;
  flex-direction: row;
  width: 95%;
  position: absolute;
  background-color: #ffffff;

  height: 80vh;
  margin: auto;
  justify-content: center;
  left: 0%;
  top: 0%;
  bottom: 0%;
  right: 0;
  padding: 0;
  /* max-width: 100rem;
  aspect-ratio: 20/10; */
}

.epic-slider .epic-slider-view {
  width: 100%;
  /* height: 30vh; */
  border-radius: 1.5rem;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.epic-slider .epic-slider-view .slider-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 250ms ease-in-out;
  transform: translate(-2rem, 0);
}

.epic-slider .epic-slider-view .slider-content.preview {
  z-index: 2;
  transform: translate(2rem, 0);
  opacity: 0;

}

.epic-slider .epic-slider-view .slider-content.active {
  transform: translate(0, 0);
  opacity: 1;
  z-index: 4;

}

.epic-slider .epic-slider-view .slider-content.active .infotypes h3,
.epic-slider .epic-slider-view .slider-content.active .infotypes p,
.epic-slider .epic-slider-view .slider-content.active .infotypes button {
  opacity: 1;
  transform: translate(0, 0);
}

.epic-slider .epic-slider-view .slider-content .infotypes {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  padding: 3rem;
  height: 100%;
  width: 60%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-image: linear-gradient(-90deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

.epic-slider .epic-slider-view .slider-content .infotypes h3 {
  color: #ffffff;
  position: absolute;
  font-size: 5vmin;
  text-align: right;
  text-transform: uppercase;
  bottom: 18%;
  width: 80%;
  opacity: 0;
  transition: 350ms 150ms ease-in-out all;
  /* transform: translate(5rem, 0); */
}
.reftypes {
  color: #f1eded;
  position: absolute;
  font-size: 3vmin;
  text-transform: uppercase;
  bottom: 8%;
  width: 80%;
  text-align: right;
cursor: pointer;
  transition: 350ms 150ms ease-in-out all;
}

.epic-slider .epic-slider-view .slider-content .backgroundtypes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.epic-slider .epic-slider-preview {
  height: 100%;
  z-index: 0;
  margin: 0 0 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}



.epic-slider .epic-slider-preview .preview-element {
  background-color: rgba(255, 255, 255, 0.05);
  margin: 0 0 1rem 0 0;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh;
  cursor: pointer;
  border: none;
  transition: all 250ms ease-in-out;
  position: relative;
}

/* .epic-slider .epic-slider-preview .preview-element:hover {
  background-color: rgba(255, 255, 255, 0.1);
} */

.epic-slider .epic-slider-preview .preview-element:last-child {
  margin: 0 0 0 0 0;
}

.epic-slider .epic-slider-preview .preview-element.active .imgtypes {
  -webkit-animation: bounce 250ms ease-in-out forwards;
  animation: bounce 250ms ease-in-out forwards;
}

.epic-slider .epic-slider-preview .preview-element.active::after {
  -webkit-animation: process 5.1s linear;
  animation: process 5.1s linear;
  opacity: 1;
}

.epic-slider .epic-slider-preview .preview-element::after {
  content: "";
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.05);
  width: 0;

  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 350ms opacity ease-in-out;
}

.epic-slider .epic-slider-preview .preview-element .imgtypes {
  pointer-events: none;
  height: 10vh;

  aspect-ratio: 6/4;
  border-radius: 1.05rem;
  background-position: center center;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

@media only screen and (width<=1050px) {
  .epic-slider .epic-slider-view {
    width: 90vmin;
    height: 30vh;
    top: 0%;

  }
  .epic-slider {
    
    top: 5%;
  
  }
  

  .epic-slider .epic-slider-preview {
    position: absolute;
    height: 50%;
    margin: 0 0 0 0 0 0;
    display: grid;
    bottom: 8%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .epic-slider .epic-slider-preview .preview-element .imgtypes {
    height: 14vh;
    width: 40vmin;
    /* aspect-ratio: 6/4; */
    border-radius: 1.05rem;
    background-position: center center;
    object-fit: cover;
    position: relative;
    z-index: 2;
  }
  .epic-slider .epic-slider-view .slider-content .infotypes h3 {
    color: #ffffff;
    position: absolute;
    font-size: 4vmin;
    text-align: right;
    text-transform: uppercase;
    bottom:25%;
    width: 60%;
    opacity: 0;
  
  }
  .reftypes {
    color: #f1eded;
    position: absolute;
    font-size: 3vmin;
    text-transform: uppercase;
    bottom: 8%;
    width: 60%;

  }
}

/* .epic-slider .epic-slider-preview .preview-element p {
  pointer-events: none;
  margin: 0 0 0 1.5rem;
  padding: 0;
  font-weight: 700;
  text-align: left;
  line-height: 1.4em;
  color: #ffffff;
  font-size: 1.4rem;
  position: relative;
  z-index: 2;
} */

@-webkit-keyframes process {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes process {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}