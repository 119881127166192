.paysection {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #051d46;
    background-image: radial-gradient(#194b85, #03041f);
    background-size: 100%;
    color: #fff;
    text-align: center;
    background-size: cover;
    background-size: 100% 100%;

}

.billingAddress p {
    color: "red";
}

.paydiv {
    position: relative;
    background-color: #ffffff;
    justify-content: center;
    padding: 0;
    margin: auto;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    box-shadow: 0 0px 30pt 4pt rgba(17, 10, 10, 0.651);
    padding-top: 3vmin;
    padding-bottom: 3vmin;
    overflow: hidden;
    z-index: 4;
}

.paypal {
    width: 75vmin;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: #fffbfb;
}

.paypalmonth {
    width: 75vmin;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: #fffbfb;

}

.paydiv p {
    font-size: 3vmin;
    padding-bottom: 3vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;
    color: black;
}

.pay-text {
    font-size: 3vmin;
    padding-bottom: 3vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;
    color: black;

}

.butterfly {
    position: absolute;
    z-index: 1;
    width: 10vmin;
    height: 10vmin;
    top: 25%;
}



#rotate-words {
    color: #fff;
    text-align: center;
    position: absolute;
    text-align: center;
    justify-content: center;
    padding: 0;
    margin: auto;
    right: 0;
    left: 0;
    width: 100%;
    top: 40%;
    font-size: 4vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;

}



#rotate-words span {
    display: block;
    height: 50px;
    font-size: .7em;
    text-transform: lowercase;
    opacity: .8;
    font-family: 'Baloo Bhaijaan 2', cursive;

}

#rotate-words div {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    left: 10vw;
    width: 80vw;
    line-height: 1.2em;
    animation: rotate-word 32s linear infinite 0s;
}

@keyframes rotate-word {
    0% {
        opacity: 0;
        transform: translateX(0);
        filter: blur(10px);
        transform: scale(1.2)
    }

    3% {
        opacity: 1;
        transform: translateX(0);
        filter: blur(0px);
        transform: scale(.9)
    }

    12% {
        opacity: 1;
        transform: translateX(0);
        filter: blur(0px);
        transform: scale(1)
    }

    16% {
        opacity: 0;
        transform: translateX(0);
        filter: blur(10px);
        transform: scale(1.2)
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

#rotate-words div:nth-child(2) {
    animation-delay: 4s
}

#rotate-words div:nth-child(3) {
    animation-delay: 8s
}

#rotate-words div:nth-child(4) {
    animation-delay: 12s
}

#rotate-words div:nth-child(5) {
    animation-delay: 16s
}

#rotate-words div:nth-child(6) {
    animation-delay: 20s
}

#rotate-words div:nth-child(7) {
    animation-delay: 24s
}

#rotate-words div:nth-child(8) {
    animation-delay: 28s
}

@keyframes author {
    0% {
        opacity: 0;
        transform: translateY(100px);
        filter: blur(10px);
        transform: scaleY(2)
    }

    20% {
        opacity: 0;
        transform: translateY(200px);
        filter: blur(10px);
        transform: scaleY(2)
    }

    30% {
        opacity: 1;
        transform: translateY(0);
        filter: blur(0px);
        transform: scaleY(1)
    }

    90% {
        opacity: 1;
        transform: translateY(0);
        filter: blur(0px);
        transform: scaleY(.9)
    }

    100% {
        opacity: 0;
        transform: translateY(0);
        filter: blur(10px);
        transform: scale(2)
    }
}

/* ------------------------ */

.flashwrapper {
    background-color: #000000af;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    /* display: none; */
}

.suc {

    padding-top: 2vmin;
    list-style-type: none;
    font-family: 'Baloo Bhaijaan 2', cursive;


}

.allsuccess {
    position: absolute;
    justify-content: center;
    margin: auto;
    left: 0;
    right: 0%;
    padding: 0%;
    text-align: center;
}

.ft-green-tick {
    margin-top: -20vh;
    width: 23vh;

}

#suc1 {
    font-size: 3vmin;
}

#suc2 {
    font-size: 3vmin;
    color: #eccf51;
}

#suc3 {
    font-size: 2vmin;
    color: #ffffffa6;
}

/* 
li span {
    color: #ffffffaf;
    font-size: 2vmin;
    padding: 2vh;
}
 */

.svg-container {
    position: absolute;
    width: 100%;
    justify-content: center;
    margin: auto;
    padding: 0%;
    text-align: center;
    margin-right: 0;
    margin-left: 0;
}

.container-notification {
    display: flex;
    height: 30%;
    justify-content: center;
    align-items: center;
}

.rectangle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 50px;
    height: 50px;
    background: #058d17;
    transform: scale(0);
    border-radius: 50%;
    color: white;
    opacity: 0;
    overflow: hidden;
    animation: scale-in .3s ease-out forwards,
        expand .35s .25s ease-out forwards;
}

.notification-text {
    position: absolute;
    width: 100%;
    text-align: right;
    padding: 0 25px;
    font-family: 'Baloo Bhaijaan 2', cursive;

    font-size: 17px;
    animation: fade-in .65s ease-in forwards;
}

@keyframes scale-in {
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes expand {
    50% {
        width: 350px;
        border-radius: 6px;
    }

    100% {
        width: 300px;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, .2),
            0px 1px 1px 0px rgba(0, 0, 0, .14),
            0px 3px 3px -1px rgba(0, 0, 0, .12);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: .8;
    }
}

@supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
    .tick {
        stroke-opacity: 0;
        stroke-dasharray: 29px;
        stroke-dashoffset: 29px;
        animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
        animation-delay: .6s
    }

    .circletick {
        fill-opacity: 0;
        stroke: #219a00;
        stroke-width: 16px;
        transform-origin: center;
        transform: scale(0);
        animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
    }
}

@keyframes grow {
    60% {
        transform: scale(.8);
        stroke-width: 4px;
        fill-opacity: 0;
    }

    100% {
        transform: scale(.9);
        stroke-width: 8px;
        fill-opacity: 1;
        fill: #219a00;
    }
}

@keyframes draw {

    0%,
    100% {
        stroke-opacity: 1;
    }

    100% {
        stroke-dashoffset: 0;
    }
}






@media only screen and (width<=800px) {


    #rotate-words {
        font-size: 6vmin;
    }

    .paydiv p {
        font-size: 5vmin;

    }

    #suc1 {
        font-size: 5vmin;
    }

    #suc3 {
        font-size: 3.5vmin;
    }

    #suc2 {
        font-size: 4vmin;
    }


    li span {
        font-size: 4vmin;

    }
}



.SigninAll-pay {
    font-size: 5vh;
    position: absolute;
    background-color: #ffffff;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    height: 100vh;
    z-index: 0;
    display: none;

}


.ko-pay {
    color: #000000a8;
    z-index: 0;
    font-size: 2.5vmin;
    font-family: 'Baloo Bhaijaan 2', cursive;

    position: absolute;
    text-align: center;
    justify-content: center;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
}

.chain-pay {
    position: relative;
    width: 50vmin;
    height: 25vh;
    object-fit: contain;
    margin-top: 25vh;
    z-index: 0;

}

.btn-hover-pay {
    display: none;
    font-family: 'Baloo Bhaijaan 2', cursive;

    width: 40vmin;
    font-size: 3vmin;
    cursor: pointer;
    margin: 20px;
    height: 8vmin;
    font-weight: 600;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    position: relative;
    z-index: 0;
    margin-top: 55vh;
    border-radius: 50px;

}

.btn-hover-pay:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover-pay:focus {
    outline: none;
}


.btn-hover-pay.colorpro-1 {
    background-image: linear-gradient(to right, #ff83ef, #ffe231, #3ff1f9, #3483f9);
    box-shadow: 0 4px 15px 0 rgba(6, 80, 102, 0.699);

}

@media only screen and (width<=800px) {
    .btn-hover-pay {
        width: 50vmin;
        font-size: 5vmin;
        height: 10vmin;

    }

    .ko-pay {

        font-size: 3.5vmin;
        width: 60%;
        text-align: center;
        justify-content: center;
    }
}


/* ---------------------------- */

.tabs {
    list-style-type: none;
    margin: 0;
    padding: 0%;
    padding-bottom: 5vmin;
    overflow: hidden;
}


.tabs li {
    float: left;
    display: block;
    text-align: center;
    padding: 20px;
    text-decoration: none;

}


ul {
    list-style-type: none;
}

.tabs a {
    cursor: pointer;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    text-transform: uppercase;
    font-family: inherit;
    font-size: 16px;
    letter-spacing: .5px;
    color: #110404;
    background-color: transparent;
    border: none;
    outline: none;
    transform: translateX(0);
    transition: all .3s ease-out;
    padding-bottom: 3vmin;

}

.tabs a:hover,
.tabs a#active {
    color: #000000;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}


.btn-hover-sub {
    cursor: pointer;
    width: 15vh;
    height: 4vh;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    z-index: 7;
    border-radius: 50px;
    font-weight: 600;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}




.btn-hover-sub:hover {
    background-position: 100% 0;
}

.btn-hover-sub:focus {
    outline: none;
}



.btn-hover-sub.colorpro-1 {
    background-image: linear-gradient(to right, #ff83ef, #ffe231, #3ff1f9, #3483f9);
    box-shadow: 0 4px 15px 0 rgba(6, 80, 102, 0.699);
    font-size: 2vh;
    padding: 0.5vh;
    font-family: 'Baloo Bhaijaan 2', cursive;

}