
  
  footer {
	padding: 4vmin 2rem;
	background: #4c5b13;
	color: #fff;
	text-align: center;
	z-index: 5;
  }
  
  .social-media-list {
	position: relative;
	font-size: 22px;
	text-align: center;
	width: 100%;
	margin: 0 auto;
	padding: 0;
  }
  
  .social-media-list li a {
	color: #997104;
  }
  
  .social-media-list li {
	position: relative; 
	display: inline-block;
	height: 45px;
	width: 45px;
	margin: 10px 8px;
	line-height: 40px;
	border-radius: 50%;
	color: #fff;
	background-color: rgb(255, 255, 255);
	border: 3px solid yelloew;
	cursor: pointer; 
	transition: all .2s ease-in-out;
  }
  
  .social-media-list li:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	height: 45px;
	line-height: 40px;
	border-radius: 50%;
	opacity: 0;
	box-shadow: 0 0 0 1px #fff;
	transition: all .2s ease-in-out;
  }
  
  .social-media-list li:hover {
	background-color: #fff; 
  }
  
  .social-media-list li:hover:after {
	opacity: 1;  
	transform: scale(1.12);
	transition-timing-function: cubic-bezier(0.37,0.74,0.15,1.65);
  }
  
  .social-media-list li:hover a {
	color: #000;
  }
  

