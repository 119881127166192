
.search-holder {
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0%;
    z-index: 20;
    top: 20%;
    visibility: hidden;

}
.body.nav-active .search-holder {
    transition-delay: 0.5s;
  }
@media only screen and (width<=950px) and (height<550px) {
    .search-holder {

        top: 10%;


    }

}

.movie-list {
    width: 50%;
    margin-top: 20%;
    height: 100%;
    visibility: hidden;

}

.search-holder.active .movie-list {
    visibility: visible;
}

.search-holder .circley {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #4b5b1300;
    clip-path: circle(30px at 50% 50%);
    transition: clip-path .4s ease-in;
    -webkit-transition: clip-path .4s ease-in;
    z-index: 2;



}

.search-holder.active .circley {
    clip-path: circle(100% at 50% 50%);
    transition: clip-path .4s ease-in;
    -webkit-transition: clip-path .4s ease-in;
    background-color: #ddaa12;

}


.search-button {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.28);
    width: 7vh;
    height: 7vh;
    background-color: #ddaa12;
    border-radius: 100%;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTI4cHgiIGhlaWdodD0iMTI4cHgiPgo8cGF0aCBkPSJNNTUuMTQ2LDUxLjg4N0w0MS41ODgsMzcuNzg2YzMuNDg2LTQuMTQ0LDUuMzk2LTkuMzU4LDUuMzk2LTE0Ljc4NmMwLTEyLjY4Mi0xMC4zMTgtMjMtMjMtMjNzLTIzLDEwLjMxOC0yMywyMyAgczEwLjMxOCwyMywyMywyM2M0Ljc2MSwwLDkuMjk4LTEuNDM2LDEzLjE3Ny00LjE2MmwxMy42NjEsMTQuMjA4YzAuNTcxLDAuNTkzLDEuMzM5LDAuOTIsMi4xNjIsMC45MiAgYzAuNzc5LDAsMS41MTgtMC4yOTcsMi4wNzktMC44MzdDNTYuMjU1LDU0Ljk4Miw1Ni4yOTMsNTMuMDgsNTUuMTQ2LDUxLjg4N3ogTTIzLjk4NCw2YzkuMzc0LDAsMTcsNy42MjYsMTcsMTdzLTcuNjI2LDE3LTE3LDE3ICBzLTE3LTcuNjI2LTE3LTE3UzE0LjYxLDYsMjMuOTg0LDZ6IiBmaWxsPSIjRkZGRkZGIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);

}

.search-holder.active .search-button {
    box-shadow: none;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTI4cHgiIGhlaWdodD0iMTI4cHgiPgo8cGF0aCBkPSJNNTUuMTQ2LDUxLjg4N0w0MS41ODgsMzcuNzg2YzMuNDg2LTQuMTQ0LDUuMzk2LTkuMzU4LDUuMzk2LTE0Ljc4NmMwLTEyLjY4Mi0xMC4zMTgtMjMtMjMtMjNzLTIzLDEwLjMxOC0yMywyMyAgczEwLjMxOCwyMywyMywyM2M0Ljc2MSwwLDkuMjk4LTEuNDM2LDEzLjE3Ny00LjE2MmwxMy42NjEsMTQuMjA4YzAuNTcxLDAuNTkzLDEuMzM5LDAuOTIsMi4xNjIsMC45MiAgYzAuNzc5LDAsMS41MTgtMC4yOTcsMi4wNzktMC44MzdDNTYuMjU1LDU0Ljk4Miw1Ni4yOTMsNTMuMDgsNTUuMTQ2LDUxLjg4N3ogTTIzLjk4NCw2YzkuMzc0LDAsMTcsNy42MjYsMTcsMTdzLTcuNjI2LDE3LTE3LDE3ICBzLTE3LTcuNjI2LTE3LTE3UzE0LjYxLDYsMjMuOTg0LDZ6IiBmaWxsPSIjMmJhZGQ4Ii8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
    background-color: #fff;
    transition: background-color .2s ease;
    -webkit-transition: background-color .2s ease;
    transition-delay: .1s;

}

.search-holder .search-input {
    background-color: transparent;
    height: 7vh;
    color: #ddaa12;
    text-align: left;
    border: 0;
    box-shadow: none;
    outline: none;
    transform: translate(0, 55px);
    transition: transform .4s ease;
    font-size: 16px;
    padding: 0 20px;
    opacity: 0;
    visibility: hidden;
    
}

.search-input::-webkit-input-placeholder {
    color: #ddaa12;

}

.search-holder.active .search-input {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
    transition: transform .4s ease, opacity .2s ease;
    -webkit-transition: transform .4s ease, opacity .2s ease;
    font-size: 16px;

}

.formg {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;
    position: absolute;
    justify-content: center;
    bottom: 0%;
    top: 0%;
    width: 7vh;
    height: 7vh;
    border-radius: 30px;
    z-index: 10;
    transition: width .4s ease, background-color .4s ease;
    -webkit-transition: width .4s ease, background-color .4s ease;

}

.search-holder.active .formg {
    background-color: #fff;

    width: 300px;
    transition: width .4s ease, background-color .4s ease;
    -webkit-transition: width .4s ease, background-color .4s ease;
}




.l-containert {
    display: grid;
    position: absolute;
    justify-content: center;
    right: 0%;
    left: 0;
    margin: auto;
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    width:100%;
    top: 110%;
    padding: 30px;

}

@media screen and (max-width: 760px) {
    .l-containert {
        grid-template-columns: repeat(2, 1fr);
    }
}


.b-game-card {
    position: relative;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    z-index: 1;
    width: 30vmin;
    padding-bottom: 150%;
    perspective: 1000px;

}

.b-game-card__cover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    background-size: cover;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    transform-origin: top center;
    will-change: transform;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out;
    
}

.b-game-card__cover::after {
    display: block;
    content: "";
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background: linear-gradient(226deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 35%, rgba(255, 255, 255, 0.2) 42%, rgba(255, 255, 255, 0) 60%);
    transform: translateY(-20%);
    will-change: transform;
    transition: transform 0.65s cubic-bezier(0.18, 0.9, 0.58, 1);
}

.b-game-card:hover .b-game-card__cover {
    transform: rotateX(7deg) translateY(-6px);
}

.b-game-card:hover .b-game-card__cover::after {
    transform: translateY(0%);
}

.b-game-card::before {
    display: block;
    content: "";
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 6px 12px 12px rgba(0, 0, 0, 0.4);
    will-change: opacity;
    transform-origin: top center;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out;
}

.b-game-card:hover::before {
    opacity: 0.6;
    transform: rotateX(7deg) translateY(-6px) scale(1.05);
}

.search-alert{
    color: rgba(255, 255, 255, 0.781);
    width: 100%;
    position: absolute;
    margin: auto;
    padding: 0%;
    right: 0;
    left: 0;
    justify-content: center;
    text-align: center;
    top: 140%;


}

.search-alert h1{
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-size:3vh ;
}
