

.single-item-blue:hover {
    box-shadow: -1.5vmin 0 5vmin -1vmin #5bf5fa, 1.5vmin 0 5vmin -1vmin #6ebaf8;
}

.backgrounds-blue {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;

    overflow: hidden;
}

.background-blue {
    --offset: 0;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #3d86f3, #d2fcfc, #c1f8fa, #3d86f3);
    transform: scale(0);
    transition: all 0.4s ease 0s;
    border-radius: 5vmin;
}

.background2-blue {
    --offset: 10%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #78abf8, #d5f7fd, #cdf9ff, rgb(103, 161, 247));
    transform: scale(0);
    transition: all 0.4s ease 0.01s;
}

.background3-blue {
    --offset: 20%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #a5d2fd, #ffffff94, #ffffff8e, #a5d2fd);
    z-index: 2;
    transition: all 0.4s ease 0.02s;
}

.background4-blue {
    --offset: 30%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #b9f2fc, #ffffff8e, #ffffff96, #b9f2fc);
    z-index: 3;
    transition: all 0.4s ease 0.03s;
}

.background5-blue {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #d4f9ff, #ffffff70, #ffffff8a, #d4f9ff);
    z-index: 4;
    transition: all 0.4s ease 0.04s;
}

.background6-blue {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: white;
    z-index: 5;
    transition: all 0.7s ease 0.06s;
}


.background-blue.state-show {
    transform: scaleX(1.1);

}

.background6-blue.state-show {
    transform: scale(6);

}


#quiz-container-blue {
    position: relative;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    justify-content: center;
    width: 100%;
    height: 55vh;
    background-color: #133f92;

}


.i-amphtml-story-quiz-container-blue {
    align-self: start;
    --correct-color: #5BBA74 !important;
    --correct-color-shaded: #2D8E42 !important;
    --incorrect-color: #F34E4E !important;
    --incorrect-color-shaded: #cc4141 !important;
    --accent-color: #133f92;
    background: var(--accent-color) !important;
    border-radius: 5vmin !important;
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: 6vh;
    height: 33vh;

}

.overlay-blue {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 118, 172, 0.548)
}
.norepeat-blue {
    position: absolute;
    object-fit: cover;
    margin: auto;
    padding: 0%;
    left: 0;
    right:0;
    /* margin-top: -5vh; */
    width:100%;
    height: 100%;
}


.overlaysolved-blue {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000008f;
}

.button-text-blue {
    justify-content: center;
    text-align: center;
    font-size: 7vh;
    color: #ffffff;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
    font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}


video::-webkit-media-controls-fullscreen-button {
    display: none;
}

video::-webkit-media-controls-mute-button {
    margin-right: -40%;
}

video::-webkit-media-controls-timeline {
    padding-bottom: 0;
    margin-bottom: 14px;
}


.videoq-blue {
    position: absolute;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    width: 90%;
    height: 30vh;
    object-fit: cover;
    border-radius: 3vmin;
}


.question-head-blue {
    position: absolute;
    font-size: 2.5vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    color:black;
    text-align: center;
    filter: drop-shadow(0px 2px 4px rgb(0, 0, 0));
    font-family: 'Baloo Bhaijaan 2', cursive;
    letter-spacing: 0.2vmin;
    -webkit-text-stroke: 0.1vmin rgba(253, 246, 246, 0.966);
    top: 1vh;
}

.question-text-blue {
    position: absolute;
    font-size: 3vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    filter: drop-shadow(0px 2px 4px rgb(15, 10, 10));
    font-family: 'Baloo Bhaijaan 2', cursive;
    letter-spacing: 0.2vmin;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
    text-align: center;
    top: -18%;
    overflow: visible;
    z-index: 1;
    width: 100%;

}

#question-text-blue-show {
    position: absolute;
    font-size: 3vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    filter: drop-shadow(0px 2px 4px rgb(20, 16, 16));
    font-family: 'Baloo Bhaijaan 2', cursive;
    letter-spacing: 0.2vmin;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    margin-top: 36.5vh;
    overflow: visible;
    z-index: 1;
}

.solved-blue {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    left: 0%;
    top: -5vmin;
    width: 10vmin;
    height: 10vmin;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
}



.video-options-blue {
    color: rgb(80, 79, 79);
    text-align: center;
    font-size: 2.3vh;
    /* filter: drop-shadow(0px 1px 7px rgb(255, 253, 253)); */
    -webkit-text-stroke: 0.1vmin rgba(0, 0, 0, 0.253);
}
.i-amphtml-story-quiz-option-container-blue {
    display: flex !important;
    flex-direction: column;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 5vmin !important;
    padding: 1vmin 2vmin 1vmin 0.5vmin !important;
    position: absolute;
    width: 100%;
    height: 35vh;
    top: 43vh;
}

.answer__item-blue {
    background-color: #f6f6f6;
    display: block;
    position: relative;
    cursor: pointer;
    height: 8vh;
    width: 98.5%;
    border-radius: 8vmin !important;
    /* padding: 1vmin 2vmin 2vmin 3vmin !important; */
    /* background-color: inherit !important; */
    margin: 0.85vh;
    border: solid 1px #DADCE0 !important;
    font-size: 2vmin !important;
    line-height: 4vmin !important;
}

:valid .answer__item-blue--is-correct {
    background-color: rgba(61, 181, 64, 0.6)
}

:checked+.answer__item-blue {
    color: #ffffff;
    background-color: #c70000
}

:checked+.answer__item-blue--is-correct {
    background-color: #3db540;
}

:checked+.answer__item-blue .answer__icon {
    display: inline-block
}

:valid+.answer__item-blue {
    pointer-events: none
}

:checked+.answer__item-blue--is-correct {
    counter-increment: quiz-score
}

.Signin {
    font-size: 5vh;
    position: absolute;
    color: #2D8E42;
    top: 40%;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 5;
    transition: all 0.7s ease 0.3s;

}
@media only screen and (width<=1000px) {
    .question-text-blue {
        font-size: 3vmin;
    }
    .video-options-blue {
        font-size: 3vmin;
    }

    #question-text-blue-show {
        font-size: 3vmin;

    }
}


@media only screen and (width<=550px) and (height<920px) {

    .Signin {
        font-size: 4vh;
    }
    .video-options-blue {
        font-size: 2vh;
    }

    .solved-blue {
        position: absolute;
        top: -7vmin;
        width: 14vmin;
        height: 14vmin;
    }

    #question-text-blue-show {
        position: absolute;
        font-size:2.2vh;
        width: 100%;
    }
    .question-text-blue {
        position: absolute;
        font-size:2.2vh;
        width: 100%;

    }
    .question-head-blue {
        font-size: 2vh;
    }

}

@media only screen and (width<=300px) {
    .question-text-blue {
        font-size: 1vh;
    }

    .video-options-blue {
        font-size: 1vh;
    }

    .question-head-blue {
        font-size: 1vh;
    }

    #question-text-blue-show {
        font-size: 1vh;

    }
}