.single-item-red:hover {
    box-shadow: -1.5vmin 0 3vmin -1vmin #fc7979, 1.5vmin 0 3vmin -1vmin #fc7979;
}

.backgrounds-red {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;

    overflow: hidden;
}

.background-red {
    --offset: 0;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #fc6464, #f3b7b7, #f3b7b7, #fc6464);
    transform: scale(0);
    transition: all 0.4s ease 0s;
    border-radius: 5vmin;
}

.background2-red {
    --offset: 10%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #f39999, #fac9c9, #fdcfcf, #f39999);
    transform: scale(0);
    transition: all 0.4s ease 0.01s;
}

.background3-red {
    --offset: 20%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #f3c1b5, #ffffff, #ffffff, #f3c1b5);
    z-index: 2;
    transition: all 0.4s ease 0.02s;
}

.background4-red {
    --offset: 30%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #ffdbd2, #ffffff, #ffffff, #ffdbd2);
    z-index: 3;
    transition: all 0.4s ease 0.03s;
}

.background5-red {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #fde8e2, #ffffff70, #ffffff8a, #fde5df);
    z-index: 4;
    transition: all 0.4s ease 0.04s;
}

.background6-red {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: white;
    z-index: 5;
    transition: all 0.7s ease 0.07s;
}


.background-red.state-show {
    transform: scaleX(1.1);

}

.background6-red.state-show {
    transform: scale(6);

}

.norepeat-red {
    position: absolute;
    object-fit: cover;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    /* margin-top: -5vh; */
    width: 120%;
    height: 105%;
}

.Signin-red {
    font-size: 5vh;
    position: absolute;
    color: #2D8E42;
    top: 40%;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 5;
    transition: all 0.7s ease 0.3s;

}



.overlay-red {
    position: absolute;
    margin: auto;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #69191971
}

.overlaysolved-red {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    width: 100%;
    height: 100%;
    background-color: #0000008f;
}

.centerdiv {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    left: 0%;
}

.button-text-red {
    justify-content: center;
    text-align: center;
    font-size: 7vh;
    color: #ffffff;
    /* filter: drop-shadow(0px 4px 7px rgb(0, 0, 0)); */
    font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}


#quiz-container-red {
    position: relative;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    justify-content: center;
    width: 100%;
    height: 58vh;
    background-color: #a50c0c;

}



.videoq-red {
    position: absolute;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    width: 90%;
    height: 35vh;
    object-fit: cover;
    border-radius: 3vmin;
}

#i-amphtml-story-quiz-container-red {
    align-self: start;
    --correct-color: #5BBA74 !important;
    --correct-color-shaded: #2D8E42 !important;
    --incorrect-color: #F34E4E !important;
    --incorrect-color-shaded: #cc4141 !important;
    background-color: #a50c0c;
    background: var(--accent-color) !important;
    border-radius: 5vmin !important;
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: 7vh;
    height: 37vh;

}

.question-head-red {
    position: absolute;
    font-size: 2.5vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    text-align: center;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
    font-family: 'Baloo Bhaijaan 2', cursive;
    letter-spacing: 0.2vmin;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
    margin-top: 1.5vh;

}

.question-text-red {
    position: absolute;
    font-size: 2.2vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgba(0, 0, 0, 0.637);
    text-align: center;
    margin-top: 2vh;
    color: rgb(150, 150, 150);
    z-index: 1;

}

#question-text-red-show {
    position: absolute;
    font-size: 2.5vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
    font-family: 'Baloo Bhaijaan 2', cursive;
    letter-spacing: 0.2vmin;
    -webkit-text-stroke: 0.1vmin rgba(255, 255, 255, 0.637);
    text-align: center;
    width: 95%;
    margin-top: 43vh;
    z-index: 1;

}

.solved-red {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    left: 0%;
    top: -5vmin;
    width: 10vmin;
    height: 10vmin;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
}

.i-amphtml-story-quiz-option-container-red {
    display: flex !important;
    flex-direction: column;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 5vmin !important;
    padding: 1vmin 1vmin 0vmin 1vmin !important;
    position: absolute;
    width: 100%;
    height: 25vh;
    top: 54vh;
}


.submit-button {
    background-color: #408a23;
    display: block;
    color: white;
    margin: auto;
    position: absolute;
    cursor: pointer;
    height: 5vh;
    text-align: center;
    margin-top: 16vh;
    width: 40%;
    border-radius: 8vmin !important;
    padding: 0%;
    justify-content: center;
    left: 0;
    right: 0;
    border: solid 1px #DADCE0 !important;
    font-size: 2.5vh;
    color: #ffffff;
    box-shadow: 0px 1.1vmin 0.5vmin rgba(3, 3, 3, 0.589);
    font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}

.form-red {
    margin: auto;
    padding: 2% 3%;
    left: 0;
    right: 0;
    margin-top: 8vh;
    position: absolute;
    font-size: 2.5vh;
    justify-content: center;
    border: 1px solid rgb(145, 144, 144);
    border-radius: 5vh;
    font-family: 'Baloo Bhaijaan 2', cursive;
    height: 5vh;
    width: 90%;
}


.clap {
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    justify-content: center;
    margin-top: -1.5vh;
    width: 9vh;
    height: 9vh;
    z-index: 1;

}

#disabledbutton {
    pointer-events: none;
    background-color: #a6a7a6c2;

}

.redandgreennote {
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 4.7vh;
    font-size: 1.8vh;
    position: absolute;
    justify-content: center;
    color: rgb(145, 133, 133);
    font-family: 'Baloo Bhaijaan 2', cursive;

}

@media only screen and (width<=550px) and (height<920px) {
    .redandgreennote {
        margin-top: 5vh;
        font-size: 1.5vh;
    
    }

    .form-red {
        font-size: 2vh;
    }

    .solved-red {
        position: absolute;
        top: -7vmin;
        width: 14vmin;
        height: 14vmin;
    }


    /* .question-text-red {
        font-size: 1.9vh;
    } */



}

@media only screen and (width<=260px) {
    .question-text-red {
        font-size: 1vh;
    }
    .redandgreennote {
        font-size: 1vh;
    }
    .question-head-red {
        font-size: 1vh;
    }

    #question-text-red-show {
        font-size: 1vh;

    }

    .submit-button {
        font-size: 1vh;
        height: 2vh;
    }

    .form-red {
        font-size: 1vh;
        height: 3vh;
    }

    .clap {

        margin-top: 0vh;
        width: 5vh;
        height: 5vh;


    }


}