
.firstinfo,
.badgescard {
  display: flex;
  justify-content: center;
  align-items: center;

}


.color {
  position: absolute;
  width: 100%;
  height: 100vh;
  /* -webkit-background-size: cover;
  -moz-background-size: cover; */
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: 14px;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

}

.color::before {
  content: '';
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  background: #c0392b;
  /* IE Fallback */
  background: rgba(0, 0, 0, 0.644);
  width: 100%;
  height: 100%;
}

.color2 {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url("https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/4EE9B14A6912788DAA1274C4A7B7BF89B5571735314DADB0D1962686D11C3DDF/scale?aspectRatio=1.78&format=jpeg") no-repeat center center fixed;
  ;
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; */
  background-size: cover;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: 14px;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  margin-top: -1.8%;
  margin-bottom: -1%;


}

.contentp {
  position: relative;
  /* animation: animatop 0.2s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards; */
  margin: auto;
  width: 70%;
  justify-content: center;
  margin: auto;
  padding: 0;
  left: 0%;
  right: 0;
  margin-top: 13vh;

}

.cardp {
  position: absolute;
  height: 80vh;
  margin: auto;
  padding: 0;
  left: 0%;
  right: 0;
  padding: 15%;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(10, 2, 2, 0.397);
  overflow: hidden;
  border: 3px solid rgb(0, 0, 0);
}

.cardp:after {
  content: "";
  display: block;
  width: 20%;
  height: 140vh;
  background: #e9ad07;
  position: absolute;
  animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both;
}

#container2 {
  width: 100%;
  height: 100%;

  position: relative;

}

#navi,
#infoi {

  position: absolute;
  margin-left: 80%;
  margin-top: -10%;
}

#infoi {
  z-index: 1;
}

.badgescard {
  padding: 70px 20px;
  border-radius: 3px;
  background-color: #ECECEC;
  width: 94%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: -1;
  left: 10px;
  bottom: 10px;
  /* animation: animainfos 0.5s cubic-bezier(0.425, 1.04, 0.47, 1.105) 0.75s forwards; */
}

.badgescard span {
  font-size: 1.6em;
  margin: 0px 6px;
  opacity: 0.6;

}

.ty {
  margin-bottom: -90px;
}

.firstinfo {
  flex-direction: row;
  z-index: 1;
  position: absolute;
  top: -8%;
  justify-content: center;
  margin: auto;
  left: 0;
  right: 0;
  width: 50%;
}
.gifloader {
  z-index: 2;
  opacity:0;
  position: absolute;
  top: 8%;
  justify-content: center;
  margin: auto;
  left: 0%;
  right: 0%;
  width:8%;
  padding: 0%;
}

.firstinfo img {
  width: 12vh;
  height: 12vh;
  border: 2px solid rgb(255, 0, 0);
  object-fit: cover;
}

.pimg {
  border-radius: 50%;
}

.firstinfo .profileinfo {
  padding: 0px 20px;
}

.firstinfo .profileinfo h1 {
  font-size: 1.8em;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-weight: 500;
  letter-spacing: 0.05vmin;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  padding: 0;
  justify-content: center;
  border-bottom: 3px solid rgba(255, 196, 0, 0.822);

}

.firstinfo .profileinfo h3 {
  font-size: 1.2em;
  color: #009688;
  font-style: italic;
}

.firstinfo .profileinfo p.bio {
  padding: 10px 0px;
  color: #5a5a5a;
  line-height: 1.2;
  font-style: initial;
}


/* 
@keyframes animatop {
  0% {
    opacity: 0;
    bottom: -500px;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@keyframes animainfos {
  0% {
    bottom: 10px;
  }

  100% {
    bottom: -42px;
  }
} */

@keyframes rotatemagic {
  0% {
    opacity: 0;
    transform: rotate(0deg);
    top: -24px;
    left: -253px;
  }

  100% {
    transform: rotate(-15deg);
    top: -24px;
    left: -78px;
  }
}


/* -------- */



.container-profile {
  width: 100%;
  height: 70vh;
  position: absolute;
  justify-content: center;
  /* background-color: #009688; */
  display: flex;
  flex-direction: column;
  padding: 5vh 0 80px;
  margin: auto;
  padding: 0;
  text-align: center;
  bottom: 0%;
  left: 0%;

}



.select-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 13vmin);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 1em auto 3em;
  background: rgba(255, 255, 255, 0.5);
  padding: 4px 4px;
  justify-content: center;
  margin: auto;
  padding: 0%;
  left: 0;
  right: 0%;
  top: 3%;
  width: 100%;
}



.character {
  width: 13vmin;
  height: 13vh;

  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  text-align: center;
  padding: 0;
  border: 0;
  margin: 0;
  display: inline-flex;
}
.allArticals{
  position: relative;
  height: 80vh;
  top: 5%;
}
@media only screen and (width<=550px) and (height<920px) {
  .cardp:after {
    display: none;
  }
  .allArticals{
    top: 0%;
  }

  .select-container {
    grid-template-columns: repeat(3, 20vmin);
  }



  .character {
    width: 20vmin;
    height: 13vh;
  }


}

@media screen and (width< 900px) {
  .contentp {
    width: 90%;

  }

  /* .firstinfo img {
      height: 10vh;
      border: 2px solid rgb(255, 0, 0);
    } */
}

.character:focus,
.character:active {
  outline: 0;
}

.character__name {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: none;
}

.character__img,
.character__name {
  margin: 0;
  padding: 0;
}

.character__img {
  filter: grayscale(0.84);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 3px solid rgb(255, 196, 0);

}

.character.active {
  transform: scale(1.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.character.active .character__name {
  display: block;
  width: 100%;
  background: #e3231e;
  color: white;
  opacity: 0.7;
  margin-top: 0.8em;
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.3em 0;
}


.character.active .character__img {
  filter: grayscale(0);
  outline: 4px solid white;
  /* -webkit-animation: flash 300ms linear; */
  /* animation: flash 300ms linear; */
}

.list-footer {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  font-family: 'Baloo Bhaijaan 2', cursive;
  color: white;
  text-align: center;
  font-size: 0.7em;
  padding: 1em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.list-footer .heart {
  color: tomato;
  font-size: 2em;
  vertical-align: middle;
}

@-webkit-keyframes flash {
  0% {
    filter: brightness(100%);
  }

  20% {
    filter: brightness(150%);
  }

  40% {
    filter: brightness(100%);
  }

  60% {
    filter: brightness(150%);
  }

  80% {
    filter: brightness(100%);
  }

  100% {
    filter: brightness(150%);
  }
}

@keyframes flash {
  0% {
    filter: brightness(100%);
  }

  20% {
    filter: brightness(150%);
  }

  40% {
    filter: brightness(100%);
  }

  60% {
    filter: brightness(150%);
  }

  80% {
    filter: brightness(100%);
  }

  100% {
    filter: brightness(150%);
  }
}

/* --pagination-- */
.tabs-container {

  width: 50%;
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0;
  justify-content: center;
}

.tabs-block-profile {
  display: flex;
  padding: 0;
  flex-direction: row;
  border-radius: 100px;
  width: 30%;
  height: auto;
  position: absolute;
  z-index: 1;
  bottom: 2vh;
  left: 0%;
  right: 0;
  margin: auto;
}

.slider-q-profile {
  position: absolute;
  top: 0;
  background: #f1ce05;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  height: 5.5vmin;
  width: 22%;
  margin-left: 5%;
  list-style: none;
  color: white;

}

.li-q-profile {
  text-align: center;
  margin: 0;
  padding: 0.5vmin;
  flex: auto;
  font-size: 3vmin;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-weight: 700;
  color: #3f3f3f;
  position: relative;
  cursor: pointer;

  list-style: none;
}

.article-profile {
  position: absolute;
  width: 100%;
  height: 81vh;
  left: 0;
  margin-top: 8vh;
  line-height: 1.5;
  overflow: hidden;
}

@media only screen and (width<=550px) and (height<920px) {



  .article-profile {
    margin-top: 6vh;

  }


  .li-q-profile {
    text-align: center;
    margin: 0;
    padding: 0.3vmin;
    flex: auto;
    font-size: 4vmin;
    font-weight: 700;
    /* color: #ffffff; */
    position: relative;
    cursor: pointer;
    list-style: none;
  }
}