.pdfobject-comments-container {
  height: 30rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
  
}
iframe#webpack-dev-server-client-overlay{display:none!important}

.trigger {
  position: absolute;
  z-index: 1;
  width: 8vmin;
  height: 8vmin;
  border: none;
  margin-left: 1vh;
  top: 1vh;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-weight: 300;
  background-color: #18385000;
}

.edit-trigger {
  position: absolute;
  z-index: 10;
  width: 7vmin;
  height: 7vmin;
  border: none;
  margin-left: 4%;
  bottom: 38vh;
  font-weight: 300;
  background-color: #18385000;
}

.edit-trigger-gr {
  position: absolute;
  z-index: 10;
  width: 7vmin;
  height: 7vmin;
  border: none;
  margin-left: 4%;
  bottom: 30vh;
  font-weight: 300;
  background-color: #18385000;
}

.trigger-create {
  position: absolute;
  z-index: 3;
  width: 10vmin;
  height: 10vmin;
  border: none;
  margin-left: 0vh;
  top: -1.5vmin;
  font-weight: 300;
  background-color: #18385000;

}

.comment-png {
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  right: 0;
  justify-content: center;
  padding: 0%;
  object-fit: cover;
  position: absolute;
}

.comments-num {
  position: absolute;
  z-index: 1;
  width: 6vmin;
  height: 6vmin;
  border: none;
  font-size: 2.4vmin;
  margin-left: -3vmin;
  margin-top: -1.5vmin;
  font-weight: 500;
  color: #183850;

}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0vh;
  background-color: transparent;
  overflow: hidden;
  transition: background-color 0.25s ease;
  z-index: 9999;


}

.modal.openo {
  position: absolute;
  width: 100%;
  height: 81vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.25s;
  width: 100%;
  height: 100vh;
  /* top: -10vh; */
  top: 0%;

  position: fixed;
  left: 0px;
  content: "";
  background: rgba(0, 0, 0, 0.541);
  z-index: 100;
}

.modal.openo>.content-wrapper {
  transform: scale(1);
}

.modal .content-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  height: 75vh;
  margin: 0;
  top: 3%;
  padding: 4vh;
  background-color: white;
  border-radius: 0.3125rem;
  box-shadow: 0 0 2.5rem rgb(0, 0, 0);
  transform: scale(0);
  transition: transform 0.25s;
  transition-delay: 0.15s;
}

.modal .content-wrapper .close {
  position: absolute;
  top: 0.5vh;
  right: 0.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vh;
  height: 5vh;
  z-index: 7;

  border: none;
  background-color: transparent;
  transition: 0.25s linear;
}

.modal .content-wrapper .close:before,
.modal .content-wrapper .close:after {
  position: absolute;
  content: "";
  width: 5vmin;
  height: 1vmin;
  background-color: black;
}

.modal .content-wrapper .close:before {
  transform: rotate(-45deg);
}

.modal .content-wrapper .close:after {
  transform: rotate(45deg);
}

.modal .content-wrapper .close:hover {
  transform: rotate(360deg);
}

.modal .content-wrapper .close:hover:before,
.modal .content-wrapper .close:hover:after {
  background-color: rgb(255, 202, 26);
}

.comments-header {
  position: absolute;
  font-size: 3vh;
  margin: auto;
  padding: 0%;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  top: 3vh;
  border-bottom: 2px solid #92929269;
  font-weight: bold;
  font-family: 'Baloo Bhaijaan 2', cursive;
}

.comments-form {
  margin: auto;
  padding: 2% 3%;
  right: 2%;
  bottom: 1.5vh;
  position: absolute;
  font-size: 2.5vh;
  justify-content: center;
  border: 1px solid rgb(145, 144, 144);
  border-radius: 5vh;
  font-family: 'Baloo Bhaijaan 2', cursive;
  height: 5vh;
  width: 70%;
  z-index: 1;
  background-color: #18385000;


}

.comments-form-readonly {
  margin: auto;
  color: #dab40b;
  right: 5%;
  bottom: 5.3vh;
  position: absolute;
  font-size: 2vh;
  justify-content: center;
  border: none;
  font-family: 'Baloo Bhaijaan 2', cursive;
  height: 5.3vh;
  width: 100%;
  text-align: right;
  background-color: #18385000;

}

.submit-comments {
  background-color: #e4c933;
  display: block;
  color: white;
  margin: auto;
  position: absolute;
  cursor: pointer;
  height: 4vh;
  text-align: center;
  bottom: 2.2vh;
  object-fit: cover;
  width: 14%;
  border-radius: 8vmin !important;
  padding: 0%;
  justify-content: center;
  margin-right: 65%;
  border: solid 1px #DADCE0 !important;
  font-size: 2vh;
  color: #ffffff;
  filter: drop-shadow(0px 0.5vmin 0.5vmin rgba(0, 0, 0, 0.589));
  font-family: 'Baloo Bhaijaan 2', cursive;
  -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}

.submit-comments-reply {
  background-color: #e4c933;
  display: block;
  color: white;
  margin: auto;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  height: 5vh;
  text-align: center;
  bottom: 1.7vh;
  object-fit: cover;
  width: 14%;
  border-radius: 8vmin !important;
  padding: 0%;
  justify-content: center;
  margin-right: 80%;
  border: solid 1px #DADCE0 !important;
  font-size: 2vh;
  color: #ffffff;
  filter: drop-shadow(0px 0.5vmin 0.5vmin rgba(0, 0, 0, 0.589));
  font-family: 'Baloo Bhaijaan 2', cursive;
  -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}

.submit-comments-close {
  background-color: #e4c933;
  display: block;
  color: white;
  margin: auto;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  text-align: center;
  bottom: 2.3vh;
  object-fit: cover;
  width: 1.6em;
  height: 1.6em;
  font-family: 'Baloo Bhaijaan 2', cursive;
  border-radius: 50%;
  padding: 0%;
  justify-content: center;
  border: solid 1px #DADCE0 !important;
  font-size: 2vh;
  color: #ffffff;
  filter: drop-shadow(0px 0.5vmin 0.5vmin rgba(0, 0, 0, 0.589));
  -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
  float: left;
  margin-right: 54.5%;
}

.nocomment {
  margin: auto;
  justify-content: center;
  margin-left: 10%;
  margin-right: 0%;
  padding: 0%;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: 3vmin;
  margin-top: 22vh;
  text-align: center;
  color: #ffffff8f;
}

@media only screen and (width<=550px) and (height<920px) {
  .comments-header {
    font-size: 2.5vh;
    top: 3vh;
  }

  .comments-form {
    font-size: 2vh;
  }

  .comments-form-readonly {
    font-size: 2vh;

  }

  .submit-comments {
    font-size: 3vmin;

  }

  .trigger {
    position: absolute;
    width: 10vmin;
    height: 10vmin;

  }

  .comments-num {
    font-size: 3.3vmin;
    margin-top: -2.3vmin;

  }


  .trigger-create {
    position: absolute;
    width: 20vmin;
    height: 20vmin;
    border: none;
    margin-left: -3vmin;
    top: 0vh;

    font-weight: 300;
    background-color: #18385000;

  }


}

@media only screen and (width<=260px) {
  .comments-header {
    font-size: 1vh;

  }

  .submit-comments {
    font-size: 1vh;

  }

  .comments-form {
    font-size: 1vh;
  }
}




.floating-chat {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  overflow: hidden;
  top: 10vh;
  margin: auto;
  width: 100%;
  left: 0;
  right: 0;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  background: -moz-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
  background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);

}


.floating-chat.expando {
  width:97%;
  height: 55vh;
  border-radius: 5px;
  cursor: auto;
  opacity: 1;
}


.floating-chat :focus {
  outline: 0;
  box-shadow: 0 0 3pt 2pt rgba(14, 200, 121, 0.3);
}


.floating-chat .chat {
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
  transition: all 250ms ease-out;
  margin: auto;
  top: 0;
  left: 0;
  right: 2vh;
  bottom: 0;

}

.floating-chat .chat.entero {
  opacity: 1;
  border-radius: 0;
  margin: 1vh;
  width: auto;
  height: auto;

}


.floating-chat .chat .messageso {
  padding: 1vh;
  margin: 0;
  width: 95%;
  position: absolute;
  list-style: none;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  flex-grow: 1;
  border-radius: 4px;
  background: transparent;

}

.floating-chat .chat .messageso::-webkit-scrollbar-thumb {
  background-color: #fadd3a;
  border-radius: 12px;
  background-clip: content-box;
}

.floating-chat .chat .messageso::-webkit-scrollbar {
  width: 1vh;
  margin-right: 3vh;

}

.floating-chat .chat .messageso::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(25, 112, 147, 0.1);
}

.floating-chat .chat .messageso li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 1.5vh;
  margin: 0 0 4vh 0;
  /* font: 2.5vh "Noto Sans", sans-serif; */
  border-radius: 10px;
  word-wrap: break-word;
  max-width: 90%;
  text-align: right;
}

.othero:before {
  content: '';
  width: 0.2vh;
  height: 40vh;
  background: #5e657c;
  position: absolute;
  right: -5.6vh;
  top: 0;

}

.remove-comment {
  cursor: pointer;

  position: absolute;
  width: 2vmin;
  height: 2vmin;
  border: none;
  top: 4vh;
  right: -6vh;
  font-weight: 300;
  background-color: #ffffff;
  border-radius: 50pc;
}

.useravatar {
  position: absolute;
  top: -1vh;
  right: -8vh;
  width: 6vh;
  height: 6vh;
  border-radius: 25pc;
  background-size: cover;
  object-fit: cover;
}

.username {
  margin-top: -2vh;
  text-align: right;
  padding: 1vh;
  font-size: 2vh;
  color: #f1ebeb6c;
}

.floating-chat .chat .messageso li:after {
  position: absolute;
  top: 1vh;
  content: "";
  width: 0;
  height: 0;
}

.othero {
  background-color: rgba(25, 147, 147, 0.2);
  width: 100%;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  animation: show-chat-odd 0.15s 1 ease-in;
  float: right;
  right: 3vh;
  color: #ffffff;
}



.floating-chat .chat .messageso li.othero:after {
  border-top: 2vh solid rgba(25, 147, 147, 0.2);

  border-right: 10px solid transparent;
  margin-left: 1.5vh;
}

.othero2 {
  background-color: rgba(12, 198, 255, 0.233);
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  animation: show-chat-odd 0.15s 1 ease-in;
  float: right;
  width:70%;
  right: 6vh;
  color: #ffffff;
}

.othero2:after {
  border-top: 2vh solid rgba(12, 198, 255, 0.233);
  border-right: 10px solid transparent;
  margin-left: 1.5vh;
}

.othero2-bef:before {
  content: '';
  width: 3.5vh;
  height: 0.1vh;
  background: #5e657c;
  position: absolute;
  right: -8.5vh;
  top: 1vh;
  bottom: 9vh;

}

.othero2:before {
  content: '';
  width: 0.2vh;
  height: 40vh;
  background: #5e657c;
  position: absolute;
  float: right;
  right: -8.6vh;
  top: 0;

}

.useravatar2 {
  position: absolute;
  top: -1vh;
  right: -6vh;
  width: 4vh;
  height: 4vh;
  border-radius: 25pc;
  background-size: cover;
  object-fit: cover;
}

.remove-comment2 {
  cursor: pointer;
  position: absolute;
  width: 1.5vmin;
  height: 1.5vmin;
  border: none;
  top: 2.5vh;
  right: -4.8vh;
  font-weight: 300;
  border-radius: 50pc;
}

.reply {
  color: white;
  width: 80%;
  top: 0vh;

  position: relative;
}

.reply-icon {
  position: absolute;
  left: 0%;
  text-align: left;
  top: -4.5vh;
  float: right;
  width: 100%;
  font-size: 3vh;
  cursor: pointer;
}

.user-star {
  position: absolute;
  color: rgb(255, 196, 0);
  font-size: 4vh;
  margin-top: -1.6vh;
}
.c-size{
  font-size: 1.8vh;
  font-weight: 300;

}

@media only screen and (width<=550px) and (height<920px) {



  .useravatar {
    position: absolute;
    top: -1vh;
    right: -8vh;
    width: 5vh;
    height: 5vh;

  }
  .remove-comment2 {
    position: absolute;
    width: 1.8vh;
    height: 1.8vh;
    border: none;
    top: 2.5vh;
    right: -5vh;
    font-weight: 300;
  }
  
  .remove-comment {
    position: absolute;
    top: 3vh;
    right: -6.7vh;
    width: 2vh;
    height: 2vh;
  }

  .username {

    font-size: 2vh;
  }

}

@media only screen and (width<=260px) {

  .useravatar {
    position: absolute;
    top: 0vh;
    right: -5vh;
    width: 3vh;
    height: 3vh;

  }

  .remove-comment {
    position: absolute;
    top: 2.8vh;
    right: -4.5vh;
    width: 1.5vh;
    height: 1.5vh;
  }

  .username {

    font-size: 1.5vh;
  }

}


/* ---------------------- */