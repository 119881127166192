.video_intro {
  width: 100%;
  height: 90vmin;
  position: relative;
  overflow: hidden;
}

.video_intro video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video_intro:after {
  content: "";
  display: block;
  position: absolute;
  height: 70%;
  width: 100%;
  z-index: 1;
  border-radius: 0.5rem;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
}


.intro_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  z-index: 3;
}

#intro-span {
  font-size: 5vw;
  font-style: normal;
  color: rgb(255, 255, 255);
  overflow: hidden;
  /* margin-bottom: 15px; */
  font-family: 'Baloo Bhaijaan 2', cursive;
}


/* --------------------------------- */
.image-grid-animation {
  position: relative;
  height: 60vmin;
  width: 100%;
  margin: auto;
  padding: 0%;
  left: 0%;
  right: 0;
  bottom: 0%;
  background-color: #ffffff;
}

.butterflyo {
  position: absolute;
  z-index: 3;
  width: 10vmin;
  font-size: 5vmin;
  justify-content: center;
  margin: auto;
  right: 0;
  text-align: center;
  font-weight: 600;
  font-family: 'Baloo Bhaijaan 2', cursive;
  color: #6666669f;
  object-fit: cover;
  left: -33vmin;
  top: -16vmin;

}

.genrelist {
  overflow: hidden;
  cursor: -webkit-grab;
  cursor: grab;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 40vmin;
  margin-top: 19vmin;

}

.genrelist.is-dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.genrelist--wrapper {
  /* counter-reset: count; */
  z-index: 1;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
}

.genrelist--item {
  /* counter-increment: count; */
  z-index: 1;
  top: 0;
  left: 0;
  width: 30vw;
  height: 100%;
  padding: 0 1.5vw;
}

@media (max-width: 767px) {

  .genrelist--item {
    width: 40vw;
    height: 40vw;
  }
}

.genrelist--item figure {
  z-index: 1;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  width: 100%;
  height: 40vmin;
  overflow: hidden;
  pointer-events: none;
  transform-origin: center;
}

.genrelist--item figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: middle;
  transform-origin: center;
  background-color: #000000;

}

.genrelist--item figure:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.849),
      rgba(0, 0, 0, 0.363), rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.082),
      rgba(10, 0, 0, 0.486));
}

.item-desc-mov {
  padding: 0 1.5vh 12px;
  color: #fff;
  z-index: 1;
  overflow: hidden;
  /* transform: translateY(calc(100% - 10%));
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; */
  position: absolute;
  width: 100%;
  height: 100%;
  color: #ffffff;
  filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
  font-family: 'Baloo Bhaijaan 2', cursive;
  -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
  bottom: 0;
  height: 8vh;
}

.ff-right {
  position: absolute;
  justify-content: center;
  top: 1vmin;
  bottom: 0;
  right: 1.5vmin;
  z-index: 2;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: 3vmin;
  -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);


}

.backgroundPosters {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item-desc-mov-font {
  font-size: 3vmin;
}

@media only screen and (width<=950px) {


  .butterflyo {

    width: 13vmin;
    left: -35vmin;
    top: -18vmin;

  }

  .item-desc-mov {
    height: 6vh;


  }

}



.f-right {
  position: absolute;
  float: right;
  right: 8%;
  top: 0%;
  filter: drop-shadow(0px 1px 5px rgb(235, 215, 179));
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: 3vmin;

}

/* -------------------------------- */
.flex {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin-top: 10%; */
  background-color: #ffffff;
}

.loadwrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

}

.contentmoremovie {
  position: relative;
  height: 46vmin;
  width: 35vmin;
  color: #fff;
  font-size: 24px;
  text-align: center;
  background-color: rgb(219, 26, 26);
  margin: 17px;
  border: 1px solid lightgrey;
  display: none;
  overflow: hidden;
}

.contentmoremovie:after {
  position: absolute;
  content: "";
  display: block;
  height: 60%;
  width: 100%;
  z-index: 1;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(218, 22, 22, 0), rgba(0, 0, 0, 0.911));
}

.loaddiv {
  position: relative;
  cursor: pointer;
  outline: none;
  background-color: rgb(255, 255, 255);
  height: 15vh;
}

.item-desc-more {
  color: #fff;
  z-index: 2;
  overflow: hidden;
  -webkit-transform: translateY(calc(100% - 8vmin));
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #ffffff;
  filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
  font-family: 'Baloo Bhaijaan 2', cursive;
  -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}


.more-right {
  position: absolute;
  float: right;
  right: 5%;
  top: 4%;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: 3vmin;

}

.loadtext {
  position: relative;
  font-size: 5vmin;
  justify-content: center;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  font-family: 'Baloo Bhaijaan 2', cursive;
  color: #6666669f;
  /* background-color: #facc34; */
  height: 18vmin;
  padding-top: 6.5vmin;
  /* filter: drop-shadow(0px 4px 2px rgba(255, 254, 254, 0.89)); */
  /* -webkit-text-stroke: 0.1vmin rgb(71, 56, 29); */
  background-color: #fff;

}

#loadMore {
  position: absolute;
  width: 50vmin;
  color: #fff;
  display: block;
  text-align: center;
  padding: 10px;
  justify-content: center;
  margin: auto;
  left: 0;
  right: 0;
  cursor: pointer;
  outline: none;
  z-index: 2;
  margin-top: 3%;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: rgb(211, 134, 18);
  transition: .3s;
}

#loadMore:hover {
  outline: none;

}

.noContent {
  color: #000 !important;
  background-color: transparent !important;
  pointer-events: none;
}

/* --- */



/* -------- title style ------- */
.line-title {
  position: relative;
  width: 400px;
}

.line-title::before,
.line-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  border-radius: 2px;
}

.line-title::before {
  width: 100%;
  background: #f2f2f2;
}

.line-title::after {
  width: 32px;
  background: #e73700;
}

/******* Middle section CSS Start ******/
/* -------- Landing page ------- */
.game-section {
  padding: 60px 50px;
}

.game-section .owl-stage {
  margin: 15px 0;
  display: flex;
  display: -webkit-flex;
}

.game-section .item {
  margin: 0 15px 60px;
  width: 320px;
  height: 400px;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  background: #343434 no-repeat center center / cover;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.game-section .item.active {
  width: 500px;
  box-shadow: 12px 40px 40px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 12px 40px 40px rgba(0, 0, 0, 0.25);
}

.game-section .item:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.game-section .item-desc {
  padding: 0 24px 12px;
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transform: translateY(calc(100% - 54px));
  -webkit-transform: translateY(calc(100% - 54px));
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.game-section .item.active .item-desc {
  transform: none;
  -webkit-transform: none;
}

.game-section .item-desc p {
  opacity: 0;
  -webkit-transform: translateY(32px);
  transform: translateY(32px);
  transition: all 0.4s ease-in-out 0.2s;
  -webkit-transition: all 0.4s ease-in-out 0.2s;
}

.game-section .item.active .item-desc p {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.game-section .owl-theme.custom-carousel .owl-dots {
  margin-top: -20px;
  position: relative;
  z-index: 5;
}

/******** Middle section CSS End *******/

/***** responsive css Start ******/

@media (min-width: 992px) and (max-width: 1199px) {
  h2 {
    margin-bottom: 32px;
  }

  h3 {
    margin: 0 0 8px;
    font-size: 24px;
    line-height: 32px;
  }

  /* -------- Landing page ------- */
  .game-section {
    padding: 50px 30px;
  }

  .game-section .item {
    margin: 0 12px 60px;
    width: 260px;
    height: 360px;
  }

  .game-section .item.active {
    width: 400px;
  }

  .game-section .item-desc {
    transform: translateY(calc(100% - 46px));
    -webkit-transform: translateY(calc(100% - 46px));
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  h2 {
    margin-bottom: 32px;
  }

  h3 {
    margin: 0 0 8px;
    font-size: 24px;
    line-height: 32px;
  }

  .line-title {
    width: 330px;
  }

  /* -------- Landing page ------- */
  .game-section {
    padding: 50px 30px 40px;
  }

  .game-section .item {
    margin: 0 12px 60px;
    width: 240px;
    height: 330px;
  }

  .game-section .item.active {
    width: 360px;
  }

  .game-section .item-desc {
    transform: translateY(calc(100% - 42px));
    -webkit-transform: translateY(calc(100% - 42px));
  }
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 8px;
    font-size: 19px;
    line-height: 24px;
  }

  .line-title {
    width: 250px;
  }

  /* -------- Landing page ------- */
  .game-section {
    padding: 30px 15px 20px;
  }

  .game-section .item {
    margin: 0 10px 40px;
    width: 200px;
    height: 280px;
  }

  .game-section .item.active {
    width: 270px;
    box-shadow: 6px 10px 10px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 6px 10px 10px rgba(0, 0, 0, 0.25);
  }

  .game-section .item-desc {
    padding: 0 14px 5px;
    transform: translateY(calc(100% - 42px));
    -webkit-transform: translateY(calc(100% - 42px));
  }
}



.carousel-container {
  overflow: hidden;
  width: 100%;
  height: 90vmin;
  position: relative;
  background-color: #000;
  box-shadow: 0 0 30px -20px #223344;
  margin: auto;
  z-index: 0;
}


/* Hide the images by default */
.mySlides {
  display: none;
}

.mySlides img {
  display: block;
  width: 100%;
  height: 90vmin;
  object-fit: cover;
}

.mySlides:after {
  content: "";
  display: block;
  position: absolute;
  height: 70%;
  width: 100%;
  z-index: 1;
  /* border-radius: 0.5rem; */
  left: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
}

/* .mySlides::after {
  content: "";
  position: absolute;
  inset: 0;
    background-image: linear-gradient(-45deg, rgba(110, 0, 255, .1), rgba(70, 0, 255, .2));
} */




.dots-container {
  position: absolute;
  bottom: 5%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%);
}

.topstar {
  position: absolute;
  width: 50%;
  text-align: center;
  margin: auto;
  justify-content: center;
  left: 0%;
  right: 0%;
  top: 55%;
  font-size: 4vmin;
  z-index: 3;

}

.dots {
  cursor: pointer;
  height: 1em;
  width: 1em;
  margin: 0 4px;
  background-color: rgba(173, 216, 230, 0.2);
  backdrop-filter: blur(2px);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.active,
.dots:hover {
  background-color: rgba(235, 217, 119, 0.8);
}

/* transition animation */
.animate {
  -webkit-animation-name: animate;
  -webkit-animation-duration: 1s;
  animation-name: animate;
  animation-duration: 2s;
}

@keyframes animate {
  from {
    transform: scale(1.1) rotateY(10deg);
  }

  to {
    transform: scale(1) rotateY(0deg);
  }
}

.bg-wrapper {
  width: 100%;
  height: 100%;
}

.section-header {
  padding-top: 5.625em;
  text-align: center;
}

.section-header h3 {
  margin-bottom: 0.5em;
}

.section-header .line-red {
  margin: 2.5em auto 2.9375em auto;
}

.line-red {
  display: block;
  width: 4em;
  height: 0.1875em;
  margin: 0 auto;
  background: #f38181;
}

/* Main Header Styles */
.main-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-family: Montserrat, sans-serif;
  color: #fff;
  background: transparent;
}

.main-header .header-wrapper {
  display: block;
  width: 70%;
  margin: 0 auto;
  padding-top: 1em;
}

.main-header .header-wrapper .main-logo {
  float: left;
  font-weight: 700;
  font-size: 1.875em;
}

.main-header .header-wrapper .main-menu {
  float: right;
  text-align: center;
}

.main-header .header-wrapper .main-menu li {
  display: inline-block;
  padding: 0.75em 1.875em;
}

.main-header .header-wrapper .main-menu li a {
  padding-bottom: 0.75em;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.875em;
}

.main-header .header-wrapper .main-menu li a:hover {
  color: #fce38a;
  border-bottom: 0.1875em solid #fce38a;
  transition: 0.1s;
}

.main-header .header-wrapper .main-menu li a span {
  font-size: 1.2em;
}

.main-header .header-wrapper .main-menu li:last-child {
  padding: 0.75em 0;
}

.main-header .header-wrapper .main-menu li:nth-child(6) a:hover,
.main-header .header-wrapper .main-menu li:last-child a:hover {
  border: none;
}

/* Section-1 Styles */
#section-1 {
  height: 38em;
  color: #fff;
  background-color: #222;
}

#section-1 .content-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

#section-1 .content-slider input {
  display: none;
}

#section-1 .content-slider .slider {
  position: relative;
  width: inherit;
  height: inherit;
  overflow: hidden;
}

#section-1 .content-slider .slider .banner {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  width: inherit;
  height: inherit;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all 0.5s ease;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper {
  height: 100%;
  padding-top: 6em;
  background-image: linear-gradient(rgba(243, 129, 129, 0.9), rgba(252, 227, 138, 0.9));
  box-sizing: border-box;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper h2 {
  padding-bottom: 0.3em;
  font-family: 'Kaushan Script', cursive;
  font-weight: 400;
  font-size: 2.5em;
  text-transform: none;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper h1 {
  font-size: 6em;
  line-height: 95%;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
  display: block;
  width: 4em;
  height: 0.1875em;
  margin: 2.5em auto;
  background: #fff;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .learn-more-button {
  padding-bottom: 5em;
  z-index: 15 !important;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .learn-more-button a {
  padding: 0.5em 2em;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 0.875em;
  color: #fff;
  text-transform: uppercase;
  border: 0.1875em solid #fff;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .learn-more-button a:hover {
  color: #e88382;
  border-color: #e88382;
  transition: 0.3s;
}

#section-1 .content-slider .slider #top-banner-1 {
  background: url('https://checkboxes-demo.webdevs.co.ua/images/mogo/banner-1.png') no-repeat center center;
  background-size: cover;
}

#section-1 .content-slider .slider #top-banner-2 {
  background: url('https://checkboxes-demo.webdevs.co.ua/images/mogo/banner-2.png') no-repeat center center;
  background-size: cover;
}

#section-1 .content-slider .slider #top-banner-3 {
  background: url('https://checkboxes-demo.webdevs.co.ua/images/mogo/banner-3.png') no-repeat center center;
  background-size: cover;
}

#section-1 .content-slider .slider #top-banner-4 {
  background: url('https://checkboxes-demo.webdevs.co.ua/images/mogo/banner-4.png') no-repeat center center;
  background-size: cover;
}

#section-1 .content-slider nav {
  position: absolute;
  bottom: 0.5em;
  width: 100%;
  z-index: 10;
  text-align: center;
}

#section-1 .content-slider nav .controls {
  display: block;
  width: 70%;
  margin: 0 auto;
  font-family: Montserrat, sans-serif;
  color: #fff;
}

#section-1 .content-slider nav .controls label {
  position: relative;
  display: inline-block;
  width: 20%;
  height: 3.1em;
  overflow: hidden;
  margin: 0 1em;
  padding-top: 1em;
  text-align: left;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 1em;
  color: #f6eac5;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s;
}

#section-1 .content-slider nav .controls label .progressbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background: #f6eac5;
  z-index: 100;
}

#section-1 .content-slider nav .controls label .progressbar .progressbar-fill {
  position: inherit;
  width: inherit;
  height: inherit;
  margin-left: -100%;
  background: #e88382;
}

#section-1 .content-slider nav .controls label span {
  font-size: 1.4em;
  font-weight: 700;
}

#section-1 .content-slider nav .controls label:hover {
  color: #e88382;
}

#section-1 .content-slider #banner1:checked~.slider #top-banner-1,
#section-1 .content-slider #banner2:checked~.slider #top-banner-2,
#section-1 .content-slider #banner3:checked~.slider #top-banner-3,
#section-1 .content-slider #banner4:checked~.slider #top-banner-4 {
  opacity: 1;
  z-index: 1;
}

#section-1 .content-slider #banner1:checked~nav label:nth-of-type(1),
#section-1 .content-slider #banner2:checked~nav label:nth-of-type(2),
#section-1 .content-slider #banner3:checked~nav label:nth-of-type(3),
#section-1 .content-slider #banner4:checked~nav label:nth-of-type(4) {
  cursor: default;
  color: #fff;
  transition: all 0.5s;
}

#section-1 .content-slider #banner1:checked~nav label:nth-of-type(1) .progressbar,
#section-1 .content-slider #banner2:checked~nav label:nth-of-type(2) .progressbar,
#section-1 .content-slider #banner3:checked~nav label:nth-of-type(3) .progressbar,
#section-1 .content-slider #banner4:checked~nav label:nth-of-type(4) .progressbar {
  background: #fff;
}

#section-1 .content-slider #banner1:checked~nav label:nth-of-type(1) .progressbar-fill,
#section-1 .content-slider #banner2:checked~nav label:nth-of-type(2) .progressbar-fill,
#section-1 .content-slider #banner3:checked~nav label:nth-of-type(3) .progressbar-fill,
#section-1 .content-slider #banner4:checked~nav label:nth-of-type(4) .progressbar-fill {
  animation: progressBarFill 5s linear;
}

/* Animations */
@keyframes progressBarFill {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0;
  }
}

/* Media Queries */
@media only screen and (min-width: 1920px) {


  #section-1 {
    height: 46em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 12em;
  }
}

@media only screen and (max-width: 1919px) {


  #section-1 {
    height: 43em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 11em;
  }
}

@media only screen and (max-width: 1680px) {

  #section-1 {
    height: 40em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 9em;
  }
}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1120px) {

  #section-1 {
    height: 35em;
  }

  #section-1 .content-slider nav {
    bottom: -0.2em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 7em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
    margin: 2em auto;
  }
}

@media only screen and (max-width: 1024px) {

  #section-1 .content-slider nav {
    bottom: 0;
  }

  #section-1 .content-slider nav .controls {
    width: 80%;
  }
}

@media only screen and (max-width: 860px) {

  #section-1 {
    height: 29em;
  }

  #section-1 .content-slider nav {
    bottom: -1em;
  }

  #section-1 .content-slider nav .controls {
    width: 90%;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h2 {
    font-size: 2em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h1 {
    font-size: 4.5em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 5em;
  }
}

@media only screen and (max-width: 768px) {

  #section-1 {
    height: 27em;
  }

  #section-1 .content-slider nav .controls {
    width: 100%;
  }

  #section-1 .content-slider nav .controls label {
    width: 19%;
    font-size: 0.8em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
    margin: 1.7em auto;
  }
}

@media only screen and (max-width: 650px) {}

@media only screen and (max-width: 480px) {

  #section-1 {
    height: 26em;
  }

  #section-1 .content-slider nav {
    bottom: -0.5em;
  }

  #section-1 .content-slider nav .controls label {
    width: 40%;
    font-size: 0.7em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h2 {
    font-size: 1.5em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h1 {
    font-size: 3em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
    margin: 1.7em auto;
  }
}