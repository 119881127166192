@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2&display=swap);

.menu-icon {
  width: 3vh;
  height: 3vh;
  position: fixed;
  z-index: 7;
  right: 3.2vh;
  top: 4vh;

  cursor: pointer;
}

.menu-wrapper{
  width: 5vh;
  height: 5vh;
  position: fixed;
  z-index:7;
  right:2vh;
  top: 2.4vh;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgb(255, 196, 0);
}
.menu-icon__line {
  height: 0.3vh;
  width: 3.5vh;
  display: block;
  background-color: #030303;
  margin-bottom: 0.5vh;
  transition: transform 0.2s ease, background-color 0.5s ease;
}
.menu-icon__line-left {
  width: 1.6vh;
}
.menu-icon__line-right {
  width: 1.6vh;
  float: right;
}


.logopng {
  width: 9vh;
  height: 9vh;
  position: fixed;
  left: 2vh;
  top: 0vh;
  cursor: pointer;
  display: flex; 
  align-items: center;
  justify-content: center;
  color: #fff;
  /* filter: drop-shadow(0px 6px 26px rgba(255, 208, 0, 0.568)); */
  font-family: 'Baloo Bhaijaan 2', cursive;
  animation: fadeIn 500ms reverse;
  z-index: 10;
}



.nav {
  position: fixed;
  z-index:6;
}
.nav:before, .nav:after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(234, 234, 234, 0.2);
  z-index: -1;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
  transform: translateX(0%) translateY(-100%);
}
.nav:after {
	background: #ffffff;
  transition-delay: 0s;
}
.nav:before {
  transition-delay: 0.1s;

}
.nav__content {
  position: fixed;
  top: 30%;
  justify-content: center;
  width: 100%;
  height: 50vh;

  text-align: center;
  font-size: calc(3vh + 10px);
  font-weight: 200;
  cursor: pointer;
visibility: hidden;

}

.nav__list-item {
  position: relative;
  display: grid;
  padding: 10px;
  transition-delay: 0.8s;
  opacity: 0;
  transform: translate(0%, 100%);
  transition: opacity 0.2s ease, transform 0.3s ease;
  margin-right: 25px;

}


.nav__list a:hover {
color: rgb(255, 230, 0);
}

/* .nav__list a:hover{
  color: yellow;
} */
.nav__list a{

color: rgb(0, 0, 0);
}
/* .nav__list-item:before {
  content: "";
  position: absolute;
  background: #000000;
  width: 20px;
  height: 1px;
  top: 100%;
  transform: translate(0%, 0%);
  transition: all 0.3s ease;
  z-index: -1;
}
.nav__list-item:hover:before {
  width: 100%;
} */

.body.nav-active .menu-icon__line {
  background-color: #000;
  transform: translateX(-1px) translateY(0px) rotate(-45deg);
}
.body.nav-active .menu-icon__line-left {
  transform: translateX(2px) translateY(2px) rotate(40deg);
}
.body.nav-active .menu-icon__line-right {
  transform: translateX(0px) translateY(-2px) rotate(38deg);
}
.body.nav-active .nav {
  visibility: visible;
}
.body.nav-active .nav:before, .body.nav-active .nav:after {
  transform: translateX(0%) translateY(0%);
}
.body.nav-active .nav:after {
  transition-delay: 0.1s;
}
.body.nav-active .nav:before {
  transition-delay: 0s;
}
.body.nav-active .nav__list-item {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
}
.body.nav-active .nav__list-item:nth-child(0) {
  transition-delay: 0.5s;
}
.body.nav-active .nav__list-item:nth-child(1) {
  transition-delay: 0.6s;
}
.body.nav-active .nav__list-item:nth-child(2) {
  transition-delay: 0.7s;
}
.body.nav-active .nav__list-item:nth-child(3) {
  transition-delay: 0.8s;
}
.body.nav-active .nav__list-item:nth-child(4) {
  transition-delay: 0.9s;
}

@media only screen and (width<=1050px) {
  .menu-icon {
    right: 3.2vh;
    top: 3.9vh;
    height: 2.3vh;
    width: 2.3vh;
 
  }
  .menu-icon__line {
    height: 0.283vh;
   
  }
  .menu-wrapper{
    width: 4.3vh;
    height: 4.3vh;
    right: 1.9vh;
    top:2.7vh;
    border-radius: 50px;
    background-color: rgb(255, 196, 0);
  }
  .body.nav-active .menu-icon__line {
    background-color: #000;
    transform: translateX(-3px) translateY(0px) rotate(-45deg);
  }
  .body.nav-active .menu-icon__line-left {
    transform: translateX(-2px) translateY(1px) rotate(40deg);
  }
  .body.nav-active .menu-icon__line-right {
    transform: translateX(4px) translateY(-1px) rotate(38deg);
  }
}
@media only screen and (height<=540px) {
  .logopng {
    width: 16vmin;
    height: 16vmin;
   
  }
  .menu-icon {
    right: 3.4vmin;
    top: 5.3vmin;
    width: 5vmin;
 
  }
  .menu-icon__line {
    height: 0.583vmin;
    width: 5vmin;

  }
  .menu-wrapper{
    width: 8vmin;
    height: 8vmin;
    right: 1.9vmin;
    top:2.7vmin;
    border-radius: 50px;
    background-color: rgb(255, 196, 0);
  }
  .body.nav-active .menu-icon__line {
    background-color: #000;
    transform: translateX(-2px) translateY(0px) rotate(-40deg);
  }
  .body.nav-active .menu-icon__line-left {
    transform: translateX(-1px) translateY(2.5px) rotate(38deg);
  }
  .body.nav-active .menu-icon__line-right {
    transform: translateX(0px) translateY(-4.5px) rotate(38deg);
  }
}
