
.single-item-green:hover {
    box-shadow: -1.5vmin 0 3vmin -1vmin rgb(72, 218, 79), 1.5vmin 0 3vmin -1vmin #17c442;
}


.backgrounds-green {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;

    overflow: hidden;
}

.background-green {
    --offset: 0;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #399631, #d6fac1, #d6fac1, #399631);
    transform: scale(0);
    transition: all 0.4s ease 0s;
    border-radius: 5vmin;
}

.background2-green {
    --offset: 10%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #50b847, #cfffcd, #cfffcd, #50b847);
    transform: scale(0);
    transition: all 0.4s ease 0.01s;
}

.background3-green {
    --offset: 20%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #8cf178, #ffffffd0, #ffffffd0, #8cf178);
    z-index: 2;
    transition: all 0.4s ease 0.02s;
}

.background4-green {
    --offset: 30%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #adff9c, #ffffff, #ffffff, #adff9c);
    z-index: 3;
    transition: all 0.4s ease 0.03s;
}

.background5-green {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: linear-gradient(0deg, #cefcc5, #ffffff, #ffffff, #cefcc5);
    z-index: 4;
    transition: all 0.4s ease 0.04s;
}

.background6-green {
    --offset: 40%;
    position: absolute;
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    top: var(--offset);
    background: white;
    z-index: 5;
    transition: all 0.7s ease 0.07s;
}


.background-green.state-show {
    transform: scaleX(1.1);

}

.background6-green.state-show {
    transform: scale(6);

}

.norepeat-green {
    position: absolute;
    object-fit: cover;
    margin: auto;
    padding: 0%;
    left: 0;
    right:0;
    /* margin-top: -5vh; */
    width:120%;
    height: 105%;
}

.Signin-green {
    font-size: 5vh;
    position: absolute;
    color: #2D8E42;
    top: 40%;
    margin: auto;
    justify-content: center;
    left: 0%;
    padding: 0;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 5;
    transition: all 0.7s ease 0.3s;

}



.overlay-green {
    position: absolute;
    margin: auto;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #1a721779
}

.overlaysolved-green {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    width: 100%;
    height: 100%;
    background-color: #0000008f;
}

.centerdiv {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    left: 0%;
}

.button-text-green {
    justify-content: center;
    text-align: center;
    font-size: 7vh;
    color: #ffffff;
    /* filter: drop-shadow(0px 4px 7px rgb(0, 0, 0)); */
  font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
}


#quiz-container-green {
    position: relative;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    justify-content: center;
    width: 100%;
    height: 58vh;
    background-color: #177006;

}



.videoq-green {
    position: absolute;
    margin: auto;
    padding: 0;
    left: 0;
    right: 0;
    width: 90%;
    height: 35vh;
    object-fit: cover;
    border-radius: 3vmin;
}

#i-amphtml-story-quiz-container-green {
    align-self: start;
    --correct-color: #5BBA74 !important;
    --correct-color-shaded: #2D8E42 !important;
    --incorrect-color: #F34E4E !important;
    --incorrect-color-shaded: #cc4141 !important;
    background-color: #177006;
    background: var(--accent-color) !important;
    border-radius: 5vmin !important;
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: 7vh;
    height: 35vh;

}

.question-head-green {
    position: absolute;
    font-size: 2.5vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    text-align: center;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
  font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgb(255, 255, 255);
    margin-top: 1.5vh;

}

.question-text-green {
    position: absolute;
    font-size: 2.2vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
  font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgba(0, 0, 0, 0.637);
    text-align: center;
    margin-top: 2vh;
    color: rgb(150, 150, 150);
    z-index: 1;

}

#question-text-green-show {
    position: absolute;
    font-size: 2.5vh;
    margin: auto;
    padding: 0%;
    left: 0;
    right: 0;
    filter: drop-shadow(0px 4px 7px rgb(73, 52, 5));
  font-family: 'Baloo Bhaijaan 2', cursive;
    -webkit-text-stroke: 0.1vmin rgba(240, 239, 238, 0.637);
    text-align: center;
    width: 95%;
    margin-top: 43vh;
    z-index: 1;

}

.solved-green {
    position: absolute;
    margin: auto;
    justify-content: center;
    padding: 0;
    right: 0%;
    left: 0%;
    top: -5vmin;
    width: 10vmin;
    height: 10vmin;
    filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));
}

.i-amphtml-story-quiz-option-container-green {
    display: flex !important;
    flex-direction: column;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 5vmin !important;
    padding: 1vmin 1vmin 0vmin 1vmin !important;
    position: absolute;
    width: 100%;
    height: 25vh;
    top: 54vh;
}


.form-green {
    margin: auto;
    padding: 2% 3%;
    left: 0;
    right: 0;
    margin-top: 8vh;
    position: absolute;
    font-size: 2.5vh;
    justify-content: center;
    border: 1px solid rgb(145, 144, 144);
    border-radius: 5vh;
  font-family: 'Baloo Bhaijaan 2', cursive;
    height: 5vh;
    width: 90%;
}



@media only screen and (width<=550px) and (height<920px) {

    .form-green {
        font-size: 2vh;
    }
    
    .solved-green {
        position: absolute;
        top: -7vmin;
        width: 14vmin;
        height: 14vmin;
    }




}

@media only screen and (width<=260px) {
    .question-text-green {
        font-size: 1vh;
    }

    .question-head-green {
        font-size:1vh;
    }
    #question-text-green-show {
        font-size:1vh;

    }

    .form-green {
        font-size: 1vh;
        height: 3vh;
    }

    
}