 .image-gridq {
   position: relative;
   height: 100vh;
   width: 100%;
   overflow: hidden;
   margin: auto;
   padding: 0%;
   top: 0%;
   left: 0%;
   right: 0;
 }

 .createyellow,
 .createred,
 .creategreen,
 .createblue {
   display: grid;
   grid-template-columns: auto auto;
   width: 100%;
   height: 55vh;
   top: 10vh;
   /* left: -50%; */
 }

 .createyellow {
   background-color: #f7c82e;
 }

 .createred {
   background-color: #580606;
 }

 .creategreen {
   background-color: #045f0b;
 }

 .createblue {
   background-color: #046c8b;
 }

 .image-gridq .image_contq {
   position: relative;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .questionphoto {
   position: absolute;
   width: 100%;
   height: 100%;

 }

 .image-gridq .image_contq:nth-child(1) {
   height: 110vh;
 }

 .questionphoto {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   width: 100%;
   background-size: cover;
   background-position: 50%;
 }


 .slider-q {
   position: absolute;
   top: 0;
   background: #f1ce05;
   box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.3);
   border-radius: 100px;
   height: 5.5vmin;
   width: 16vmin;
   margin-left: 2vmin;
   list-style: none;

 }

 .tabs-block {
   display: flex;
   padding: 0;
   flex-direction: row;
   border-radius: 100px;
   width: 80vmin;
   height: auto;
   position: absolute;
   z-index: 5;
   top: 5vh;

 }

 .li-q {
   text-align: center;
   margin: 0;
   padding: 0.5vmin;
   flex: auto;
   font-size: 3vmin;
   font-family: 'Baloo Bhaijaan 2', cursive;
   font-weight: 700;
   color: #ffffff;
   position: relative;
   cursor: pointer;

   list-style: none;
 }

 @media only screen and (width<=550px) and (height<920px) {
   .slider-q {
     position: absolute;
     top: 0.5vmin;
     background: #f1ce05;
     box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.3);
     border-radius: 100px;
     height: 6vmin;
     width: 17vmin;
     margin-left: 1vmin;
   }

   .tabs-block {
     display: flex;
     padding: 0;
     flex-direction: row;
     border-radius: 100px;
     width: 74vmin;
     height: auto;
     position: absolute;
     z-index: 1;
   }

   .li-q {
     text-align: center;
     margin: 0;
     padding: 0.3vmin;
     flex: auto;
     font-size: 4vmin;
     font-weight: 700;
     color: #ffffff;
     position: relative;
     cursor: pointer;
     list-style: none;
   }
 }


 .round-effect {
   color: #c59204;
   cursor: pointer;
   text-align: center;
   border-radius: 50%;
   height: 5vmin;
   width: 6vmin;
   margin: 0vmin;
   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
   text-decoration: none;
 }

 .arrowleft {
   position: absolute;
   top: 1.4vmin;
   color: #c59204;
   font-size: 4vmin;
   margin-left: -0.7vmin;

 }

 .arrowright {
   position: absolute;
   top: 1.4vmin;
   color: #c59204;
   font-size: 4vmin;
   margin-left: -0.7vmin;
 }

 .round-effect:hover {
   text-decoration: none;
   background: #fdd005;
   color: #FFF;

 }

 .round-effect:hover a {
   text-decoration: none;
   color: #FFF;

 }

 .pagination-data {
   justify-content: center;
   width: 50vmin;
   margin: auto;
   padding: 0;
   left: 0;
   right: 0%;
 }

 .pagination-data li {
   list-style: none;
   z-index: 1;

 }

 .centered-button {
   justify-content: center;
   margin: auto;
   left: 0;
   right: 0;
   padding: 0;
   margin-top: 5vh;

 }

 .right-button {
   position: absolute;
   right: 2%;
   height: 4vmin;
   width: 4vmin;
   top: 2%;
   border-radius: 50%;
   color: #fffcfc;
   z-index: 2;
   font-size: 1vmin;
   background-color: rgb(230, 111, 176);
 }


 .check-button {
   position: absolute;
   right: 8%;
   height: 4vmin;
   width: 4vmin;
   top: 2%;
   font-size: 1vmin;
   border-radius: 50%;
   color: #fffcfc;
   z-index: 2;
   background-color: rgb(79, 228, 248);
 }
 .check-options {
  position: absolute;
  right: 14%;
  height: 4vmin;
  width: 4vmin;
  top: 2%;
  font-size: 1vmin;
  border-radius: 50%;
  color: #fffcfc;
  z-index: 2;
  background-color: rgb(43, 218, 87);
}
 .table-filter-info {
   margin: auto;
   position: absolute;
   padding: 2vmin;
   justify-content: center;
   left: 0;
   top: 0%;
   right: 0;

 }

 .rc-pagination {
   display: flex;
   align-items: center;

 }

 .rc-pagination-item,
 .rc-pagination-prev,
 .rc-pagination-jump-prev,
 .rc-pagination-jump-next {
   margin: auto;
   padding: 0;
   justify-content: center;
   left: 0;
   right: 0;
 }

 .rc-pagination-item,
 .rc-pagination-prev,
 .rc-pagination-next,
 .rc-pagination-total-text {
   min-width: initial;
   height: auto;
   line-height: initial;
   background-color: transparent;
   border: none;
   cursor: pointer;
 }

 .rc-pagination-item a,
 .rc-pagination-item button,
 .rc-pagination-prev a,
 .rc-pagination-prev button,
 .rc-pagination-next a,
 .rc-pagination-next button,
 .rc-pagination-total-text a,
 .rc-pagination-total-text button {
   margin: auto;
   padding: 0;
   justify-content: center;
   left: 0;
   right: 0;
   height: auto;
   width: 5vmin;
   height: 5vmin;
   border-radius: 8px;
   border: 1px solid transparent;
   background-color: transparent;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 4vmin;
   font-weight: 500;
   color: #ffc517 !important;
   /* transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s; */
 }

 .rc-pagination-item.rc-pagination-item-active a,
 .rc-pagination-item.rc-pagination-item-active a:hover,
 .rc-pagination-prev.rc-pagination-item-active a,
 .rc-pagination-prev.rc-pagination-item-active a:hover,
 .rc-pagination-next.rc-pagination-item-active a,
 .rc-pagination-next.rc-pagination-item-active a:hover,
 .rc-pagination-total-text.rc-pagination-item-active a,
 .rc-pagination-total-text.rc-pagination-item-active a:hover {
   background-color: #ffd30f;
   border-color: #ffffff;
   border-radius: 30pc;
   color: #ffffff !important;
 }

 .rc-pagination-item a:hover,
 .rc-pagination-item button:hover,
 .rc-pagination-prev a:hover,
 .rc-pagination-prev button:hover,
 .rc-pagination-next a:hover,
 .rc-pagination-next button:hover,
 .rc-pagination-total-text a:hover,
 .rc-pagination-total-text button:hover {
   background-color: #eceff5;
   border-radius: 30pc;
   border-color: #eceff5;
 }



 .article-block {
   width: 90vmin;
   padding: 0px;
   top: 15%;
   overflow: hidden;
   box-shadow: 0 10px 12px -12px rgba(0, 0, 0, 0.4) inset;
   background: #ffffff;
   border-bottom: 1px solid rgba(27, 177, 165, 0.3);
   position: absolute;
   border-radius: 5vmin;
   height: 81vh;
 }


 .article {
   position: absolute;
   width: 100%;
   z-index: 0;
   height: 81vh;
   left: 0;
   top: 0;
   line-height: 1.5;
   /* overflow: hidden; */
 }

 .popup-but {
   opacity: 0;
   visibility: hidden;
   position: absolute;
   overflow: hidden;
   height: 81vh;
   width: 100%;
   margin: auto;
   justify-content: center;
   left: 0;
   top: -8vh;
   right: 0;
   padding: 0%;
   flex-shrink: 0;
   border-radius: 3px;
   z-index: 3;
   align-items: center;
   justify-content: center;
   transition: all 0.2s ease
 }

 .popup-inside {
   position: absolute;
   left: 0;
   top: 0;
   height: 100%;
   width: 100%;

   overflow: hidden;
   border-radius: 5vmin;
   box-shadow: 0 0 0 black;
   transition:
     box-shadow 0.5s ease 0.7s,
     border-radius 0.35s ease 0.7s;
 }

 .question-cards {

   letter-spacing: 0.3px;
   margin: auto;
   padding: 0;
   justify-content: center;
   left: 0;
   right: 0;

 }


 .question-container {
   display: grid;
   grid-template-columns: repeat(2, 0fr);
   flex-wrap: wrap;
   position: relative;
   justify-content: center;
   border-radius: 5vmin;
   margin: auto;
   justify-content: center;
   left: 0;
   right: 0;
   margin-top: 8vh;

 }


 .xbutton {
   cursor: pointer;
   position: absolute;
   width: 7vmin;
   height: 7vmin;
   margin-top: 1vh;
   z-index: 10;
   right: 1vh;
   filter: drop-shadow(0px 4px 4px rgb(0, 0, 0));

 }

 .xbuttonsignin {
   cursor: pointer;
   position: absolute;
   width: 7vmin;
   height: 7vmin;
   top: 3vh;
   z-index: 50;
   right: 1vh;
   filter: drop-shadow(0px 4px 7px rgb(0, 0, 0));

 }

 @media only screen and (width<=550px) and (height<920px) {

   .xbutton {
     cursor: pointer;
     position: absolute;
     width: 9vmin;
     height: 9vmin;
     margin-top: 1vh;
     z-index: 10;
     filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.815));

   }

   .xbuttonsignin {
     width: 8vmin;
     height: 8vmin;
     top: 2.5vh;
     right: 1vh;
     filter: drop-shadow(0px 3px 3px rgb(0, 0, 0));

   }

   .article-block {

     top: 10%;

   }

   .image-gridq {
     /* position: fixed; */
     /* z-index: 100; */
   }

 }

 .fixedcontainer {
   position: fixed;
   z-index: 10;
 }

 button {
   border: 1px solid rgba(255, 255, 0, 0);
 }

 .button-but {
   cursor: pointer;
   will-change: transform;
   position: relative;
   z-index: 2;
   /* -webkit-appearance: none; */
   background: none;
   outline: none;
   border: none;
   text-transform: uppercase;
   letter-spacing: 3px;
   border-radius: 2px;
   /* transition: all 0.5s ease; */
   font-weight: 600;
   font-display: swap;
   font-size: 14px;
   width: 30vmin;
   height: 15vh;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 0px;
   /* box-shadow: 0 0 0 white, 0 0 0 white; */
 }

 .button-text {
   color: #c0960d;
 }

 .try-again {
   cursor: pointer;
   position: relative;
   font-size: 5vmin;
 }

 .try-again:after {
   content: '';
   position: absolute;
   left: 15px;
   right: 15px;
   height: 1px;
   top: 100%;
   background: #000000;
   margin-top: 8px;
   transition: all 0.3s ease;
 }

 .try-again:hover:after {
   left: 40%;
   right: 40%;
 }

 .content-but {
   --offset: 0;
   position: absolute;
   left: var(--offset);
   right: var(--offset);
   bottom: var(--offset);
   top: var(--offset);
   /* display: flex; */
   align-items: center;
   justify-content: center;
   opacity: 0;
   z-index: 10;
 }

 .content-wrapper-but {
   text-align: center;
 }

 .content-but.state-show {
   opacity: 1;
   transition: all 0.3s ease 0.3s;

   transform: none;
 }

 .popup-but.state-show {
   opacity: 1;

   visibility: visible;
 }

 .popup-inside.state-show {
   border-radius: 0;

 }


 @media only screen and (width<=550px) and (height<920px) {

   .round-effect {
     color: #c59204;
     cursor: pointer;
     text-align: center;
     border-radius: 50%;
     height: 8vmin;
     width: 8vmin;
     margin: 0vmin;
     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
     text-decoration: none;
   }

   .arrowleft {
     position: relative;
     top: -2vmin;
     color: #c59204;
     font-size: 8vmin;
     margin-left: -1.5vmin;

   }

   .button-but {
     cursor: unset;
   }

   .arrowright {
     position: relative;
     top: -2vmin;
     color: #c59204;
     font-size: 8vmin;
     margin-left: -0.5vmin;
   }


   .rc-pagination-item a,
   .rc-pagination-item button,
   .rc-pagination-prev a,
   .rc-pagination-prev button,
   .rc-pagination-next a,
   .rc-pagination-next button,
   .rc-pagination-total-text a,
   .rc-pagination-total-text button {
     margin: auto;
     padding: 0;
     justify-content: center;
     left: 0;
     right: 0;
     height: auto;
     width: 9vmin;
     height: 9vmin;
     border-radius: 8px;
     border: 1px solid transparent;
     background-color: transparent;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 6vmin;
     font-weight: 500;
     color: #ffc517 !important;
     /* transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s; */
   }
 }

 /* @media only screen and (width<=260px) {
  .xbutton{
    width: 2vh;
    height: 2vh;
  
  }
} */