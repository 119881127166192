.notifications {
    position: absolute;
    width: 80vmin;
    justify-content: center;
    padding: 0%;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 1;
    margin-top: 2%;

}

.notf-pp {
    position: absolute;
    right: 4vh;
    width: 5vh;
    height: 5vh;
    border-radius: 25pc;
    content: "";
    background-size: cover;
    object-fit: cover;
}

.popoverr {
    width: 90vmin;
    height: 55vh;
    background: #eeeeee;
    transition: .5s ease-in-out;
    transform-origin: 399px 0;
    transform: scale(0);
    opacity: 0;
    justify-content: center;
    padding: 0%;
    margin: auto;
    left: 0;
    right: 0;
    padding-top: 8px;
    top: 150px;
    position: fixed;

}

.msgs {
    max-height: 53vh;
    overflow-y: scroll;
    text-align: center;

}

.msg_container {
    width: 100%;
    height: 64px;
    box-sizing: border-box;
    background: #fff;
    border-bottom: 1px solid #efefef;
    padding: 4px 16px;
    position: relative;
    text-align: left;
}

.msg_container:hover {
    background: #efefef;
    cursor: pointer;
}

.text-notf {
    background: #8B8FB1;
    width: 100%;
    border-radius: 4px;
    display: inline-block;
    position: absolute;
}

.info_1 {
    opacity: .4;
    height: 14px;
    width: 30%;
}

.info_2 {
    opacity: .2;
    height: 10px;
    width: 40%;
    right: 16px;
    top: 8px;
}

.info_3 {
    opacity: .2;
    height: 10px;
    width: 25%;
    top: 26px;
}

.info_4 {
    opacity: .3;
    width: 398px;
    height: 12px;
    top: 44px;
}

.hint-notf {
    background: #fff;
    opacity: .5;
    font-size: 10px;
    position: relative;
    margin: 8px auto;
}

.btn_all {
    width: 100%;
    text-align: center;
    background: #8B8FB133;
    cursor: pointer;
    height: 24px;
    padding-top: 6px;
}

/* navbar-notf  */
.navbar-notf {
    padding-bottom: 8px;
}

.navbar-notf .icon-notf {
    background: #ffffff33;
    color: #fff;
    font-size: 3vh;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    position: relative;
    left: 384px;
    cursor: pointer;
    width: 50%;
    justify-content: center;
    padding: 0%;
    margin: auto;
    left: 0;
    right: 0;
    padding-top: 8px;
    margin-top: 10%;
    text-align: center;
    padding-bottom: 5vh;
}

.icon-notf svg polygon {
    transform-origin: center;
    transform: scale(0);
    opacity: 0;
    transition: .25s ease-in-out;
}

.display-notf {
    transform: scale(1) !important;
    opacity: 1 !important;
}

/* ----notification---- */


.notification-box {
    position: fixed;
    right: 20px;
    cursor: pointer;
    z-index: 99;
    top: 10vh;
    /* right: 30px; */
    width: 50px;
    height: 50px;
    text-align: center;
}

.notification-bell {
    /* animation: bell 1s 1s both infinite; */
}

.notification-bell * {
    display: block;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 15px #fff;
}

.bell-top {
    width: 6px;
    height: 6px;
    border-radius: 3px 3px 0 0;
}

.bell-middle {
    width: 25px;
    height: 25px;
    margin-top: -1px;
    border-radius: 12.5px 12.5px 0 0;
}

.bell-bottom {
    position: relative;
    z-index: 0;
    width: 32px;
    height: 2px;
}

.bell-bottom::before,
.bell-bottom::after {
    content: '';
    position: absolute;
    top: -4px;
}

.bell-bottom::before {
    left: 1px;
    border-bottom: 4px solid #fff;
    border-right: 0 solid transparent;
    border-left: 4px solid transparent;
}

.bell-bottom::after {
    right: 1px;
    border-bottom: 4px solid #fff;
    border-right: 4px solid transparent;
    border-left: 0 solid transparent;
}

.bell-rad {
    width: 8px;
    height: 4px;
    margin-top: 2px;
    border-radius: 0 0 4px 4px;
}

.notification-count {
    position: absolute;
    z-index: 1;
    top: -6px;
    right: -6px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    border-radius: 50%;
    background-color: #ff4927;
    color: #fff;
}

@keyframes bell {
    0% {
        transform: rotate(0);
    }

    10% {
        transform: rotate(30deg);
    }

    20% {
        transform: rotate(0);
    }

    80% {
        transform: rotate(0);
    }

    90% {
        transform: rotate(-30deg);
    }

    100% {
        transform: rotate(0);
    }
}



/* --------- */
.col-md-8{
    justify-content: center;
    margin: auto;
    right: 0%;
    left: 0%;
    padding: 0%;
    width: 90%;
    
}
.media {
    margin-top:20px;
}

.g-mb-15{
    text-align: left;
    margin-top:-2vmin ;
}
.g-mb-15-Notif{
    text-align: center;
    margin-top:-2vmin ;
}
.g-font-size-12 {
    font-size: 0.7em;
}

.g-width-50 {
    width: 50px ;
    height: 50px;
    margin-top: 0.21429rem !important;
    margin-right: 1.07143rem !important;
    border-radius:50% ;
}
.g-width-photo {
    width: 20vh ;
    object-fit: cover;
    height: 13vh;
    border: 2px solid rgb(25, 128, 175);
    border-radius: 20px;
    margin-top: 0.51429rem !important;
}


.media-body p{
    font-family: 'Baloo Bhaijaan 2', cursive;
    margin-top:20px;
}
.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
  }
.user-com{
    color: black;
}
.my-com{
    color: rgb(29, 138, 211);
}
.g-bg-secondary {
    background-color: #fafafa !important;

}
.g-bg-secondaryNotif {
    background-color: #fafafa !important;
    /* height: 28vh; */

}
.u-shadow-v18 {
    box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.15);
}

.g-color-gray-dark-v4 {
    color: #777 !important;
}

hr{
    margin-top:60px ;
    background-color: #a3a1a1;
}

.u-link-v5{
    padding-left:5px ;
}
.ml-auto{
cursor: pointer;
}


.btn-hover-notif {
    cursor: pointer;
    width: 25vmin;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    z-index: 7;
    border-radius: 50px;
    font-weight: 600;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}




.btn-hover-notif:hover {
    background-position: 100% 0;
}

.btn-hover-notif:focus {
    outline: none;
}



.btn-hover-notif.colorpro-1 {
    background-image: linear-gradient(to right, #ff83ef, #ffe231, #3ff1f9, #3483f9);
    box-shadow: 0 4px 15px 0 rgba(6, 80, 102, 0.699);
    font-size: 2vh;
    padding: 0.5vh;
    font-family: 'Baloo Bhaijaan 2', cursive;

}
.tabsd {
    list-style-type: none;
    margin: 0;
    padding: 0%;
    padding-bottom: 2vmin;
    display: flex;
    justify-content: center; 
    
}

.tabsd li {
    float: left;
    display: block;
    text-align: center;
    padding: 20px;
    text-decoration: none;
    margin-right: 10px; 
    color: black;
    
}
.tabsd a {
    cursor: pointer;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    text-transform: uppercase;
    font-family: inherit;
    letter-spacing: .5px;
    color: #110404;
    background-color: transparent;
    border: none;
    outline: none;
    transform: translateX(0);
    transition: all .3s ease-out;


}
@media (min-width: 0){
    .g-pa-30 {
        padding: 2.14286rem !important;
    }
 
}