#sectionstyle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  width: 100%;
}

.hintpp {
  position: fixed;
  top: 40px;
  background-color: #4caf50;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
  justify-content: center;
  margin: auto;
}

.hintpp.visible {
  opacity: 1;
}

.engsec {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #001030;
  display: flex;
  align-items: center;
  justify-content: center;
}

#wrapper {
  background-color: #000000;

}

.drag-proxy {
  position: absolute;
  visibility: hidden;
}

.scroll-icon {
  height: 30px;
  position: fixed;
  top: 1rem;
  right: 1rem;
  color: #e6e6e6;
  -webkit-animation: action 4s infinite;
  animation: action 4s infinite;
}

.boxes {
  position: relative;
  transform-style: preserve-3d;
  top: 33%;
  width: 100%;
  perspective: 60vmax;
}

.box {
  transform-style: preserve-3d;
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-font-smoothing: antialiased;
  line-height: 20px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #f3f3f3;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex: 1;
  border-radius: 0.2em;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: scale-down;
  object-position: 50% 50%;
  height: 30vh;
  width: 20vh;

}

.grid__item-img {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: 50%;
  background-color: #000000;
}

.pro-image {
  position: absolute;
  width: 4vh;
  top: 1vh;
  left: 0.5vh;
}

.notready {
  position: absolute;
  width: 4vh;
  top: 1vh;
  z-index: 5;
  right: 0.5vh;
}

.pro-posters {
  position: absolute;
  width: 8vmin;
  z-index: 1;
  top: 1vmin;
}

@media only screen and (width<=1100px)and (height>600px) and (height<1400px) {
  .boxes {
    top: 33%;

  }

  .box {
    height: 25vh;
    width: 17vh;
  }

  /* .grid__item-img {
    max-height: 100vh;
  } */
}

@media only screen and (width<=950px) and (height<550px) {
  .box {
    height: 32.5vh;
    width: 22vh;
  }

  .boxes {
    perspective: 1000px;
    top: 33%;

  }

}




@media only screen and (width<=550px) and (height<920px) {
  .box {
    height: 30vh;
    width: 20vh;
  }

  .boxes {
    perspective: 1100px;
    top: 33%;

  }




}

@media only screen and (width<=260px) {
  .boxes {
    top: 33%;

  }

  .box {
    height: 10.5vh;
    width: 7.1vh;
  }

  .pro-image {
    width: 2vh;

  }

}

.heading {
  position: absolute;
  margin-top: -15%;
}

h1 {
  font-size: clamp(12px, 3vw, 40px);
  font-weight: 400;
}



.box:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  background-image: var(--src);
  background-size: cover;
  transform: translate(-50%, -50%) rotate(180deg) translate(0, -100%) translate(0, -0.5vmin);
  opacity: 0.75;
}

.box:before {
  content: "";
  position: absolute;
  bottom: 19%;
  left: 50%;
  height: 35%;
  width: 100%;
  /* background: linear-gradient(rgba(0, 0, 0, 0.336) -2%, transparent); */
  transform: translate(-50%, -50%) rotate(180deg) translate(0, -100%) translate(0, -0.5vmin) scale(1.01);
  z-index: 2;
}

.header-images {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  /* filter: drop-shadow(8px 8px 15px rgba(0, 0, 0, 0.616)); */
}

.box:nth-of-type(odd) {
  background: #b2f07500;
}

.box:nth-of-type(even) {
  background: #66b81400;
}

@supports (-webkit-box-reflect: below) {
  .box {
    /* -webkit-box-reflect: below 0.5vmin linear-gradient(transparent 0 50%, rgb(15, 15, 12) 100%); */
    /* -webkit-box-reflect: below 0 color red 50%; */

  }


}

@-webkit-keyframes action {

  0%,
  25%,
  50%,
  100% {
    transform: translate(0, 0);
  }

  12.5%,
  37.5% {
    transform: translate(0, 25%);
  }
}

@keyframes action {

  0%,
  25%,
  50%,
  100% {
    transform: translate(0, 0);
  }

  12.5%,
  37.5% {
    transform: translate(0, 25%);
  }
}



.txt {
  position: absolute;
  z-index: 0;
  justify-content: center;
  align-items: center;
  font-size: 6vmin;
  color: #fff;
  top: 13%;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  filter: drop-shadow(0.1vw 0.3vw 0 rgba(252, 143, 246, 0.596)) drop-shadow(0 -0.2vw 0 rgb(3, 15, 17));
  padding: 0;
}


.grid {
  display: grid;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5em;
  padding-left: 0.5em;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  align-items: center;
  align-content: center;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas: ".";
  grid-template-columns: 100%;
  grid-template-rows: auto;
}

.grid__item {
  width: 100%;
  height: auto;
  padding-top: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}


.section-wrapper {
  -webkit-font-smoothing: antialiased;
  line-height: 20px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #f3f3f3;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0.2em;
  perspective: 863px;
  transform: perspective(2000px) perspective(1941px);
  transform-origin: 50% 50%;
  transition: 0.3s;
}

.hover-trigger {
  -webkit-font-smoothing: antialiased;
  line-height: 20px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #f3f3f3;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(auto, max-content) minmax(auto, max-content);
  transition: 0.3s;
}









/* 
   */