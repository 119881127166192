.admin-img {
    width: 20vmin;
    height: 30vmin;
}


.create-admin {
    position: absolute;
    justify-content: center;
    margin: auto;
    padding: 0%;
    right: 0;
    left: 0;
    width: 100%;
    background-color: #141414;
    color: white;
    text-align: center;
    margin-top: -10vh;
}

/* MAIN CONTIANER */
.main-container {
    position: relative;
    justify-content: center;
    margin: auto;
    padding: 0%;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #141414;

}

.edit-trigger-admin {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    margin: auto;
    padding: 0%;
    margin-left:-50px ;
    margin-top: 0px;
    position: absolute;
    background-color: #00000000;
}

.admin-png {
    width: 4vmin;
}
.viewcomments{
    justify-content: center;
    text-align: center;
}

.admin-container {
    position: relative;
    justify-content: center;
    margin: auto;
    padding: 0%;
    right: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    background-color: #141414;
    color: white;
    text-align: center;
    padding-top: 20vh;
}

.boxr {
    position: relative;
    display: grid;
    width: 50%;
    grid-gap: 70px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    margin: auto;
    padding: 0%;
    right: 0;
    left: 0;
    height: 100%;
    background-color: #141414;

}

@media only screen and (width<=1000px) {
    .boxr {

        grid-template-columns: repeat(3, 1fr);

    }
    
}


/* ------------ */